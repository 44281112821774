import { useState, useEffect } from 'react';
import { Switch, Text, Flex } from '@mantine/core';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import {db} from "../../firebase";



function Setting() {
    const [enableRushOrder, setEnableRushOrder] = useState(true); // Default to true

    useEffect(() => {
        const fetchSetting = async () => {
            const settingDoc = await getDoc(doc(db, 'settings', 'rushOrder'));
            if (settingDoc.exists()) {
                setEnableRushOrder(settingDoc.data().enabled);
            } else {
                // If the document doesn't exist, create it with the default value (true)
                await setDoc(doc(db, 'settings', 'rushOrder'), {
                    enabled: true
                });
            }
        };
        fetchSetting();
    }, []);

    const handleToggle = async (checked: boolean) => {
        setEnableRushOrder(checked);

        // Update the setting in Firebase
        await setDoc(doc(db, 'settings', 'rushOrder'), {
            enabled: checked
        }, { merge: true });
    };

    return (
        <div>
            <Flex justify="space-between" align="center">
                <Text style={{paddingRight:10}}>Enable Rush Order</Text>
                <Switch
                    checked={enableRushOrder}
                    onChange={(event) => handleToggle(event.currentTarget.checked)}
                />
            </Flex>
        </div>
    );
}

export default Setting;
