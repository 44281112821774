/** @format */

import { Button, Flex, Image, Text } from "@mantine/core";
import { useState } from "react";
import { assets, colors } from "../constants";
import { signOut } from "../repositories/firebase";
import { useAppData } from "../contexts/AppContext";
import styled from "styled-components";

function Header({
  showLogo,
  showLogout = true,
  showTitle = true,
}: {
  showLogo: boolean;
  showLogout?: boolean;
  showTitle?: boolean;
}) {
  let title = "Pharmacy Portal";
  const { user } = useAppData();

  const [loggingOut, setLoggingOut] = useState(false);
  const {
    currentRouteData: { currentRoute },
  } = useAppData();

  const handleSignout = async () => {
    setLoggingOut(true);
    await signOut();
    setLoggingOut(false);
  };
  return (
    <Flex
      w={"100%"}
      mih={125}
      bg={colors.bg}
      justify={"space-between"}
      direction={"row"}
      gap={10}
    >
      {showLogo ? (
        <Image
          style={{
            alignSelf: "center",
          }}
          w={133}
          mt={20}
          h={128}
          src={assets.images.logo}
        />
      ) : null}

      <Flex w={"100%"} justify={"space-between"}>
        <Flex direction={"column"}>
          {showTitle ? <Title>{title}</Title> : null}

          <Title style={{ fontSize: 23 }}>{currentRoute.name}</Title>
        </Flex>

        {showLogout ? (
          <Flex gap={"sm"}>
            <Text pt={8}>{user?.email}</Text>
            <Button
              loading={loggingOut}
              variant="light"
              radius={"lg"}
              size="md"
              onClick={handleSignout}
            >
              Logout
            </Button>
          </Flex>
        ) : null}
      </Flex>
    </Flex>
  );
}

export default Header;

const Title = styled.div`
  //styleName: Mobile Header 1;
  font-family: Manrope;
  font-size: 30px;
  font-weight: 700;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 15px;
`;
