import { Navigate, Outlet } from "react-router-dom";
import { useAppData } from "../contexts/AppContext";
import { ROUTES } from "../constants/routes";

const PublicRoutes = () => {
  const { user } = useAppData();

  return user ? <Navigate to={ROUTES.OrderStatus.path} /> : <Outlet />;
};

export default PublicRoutes;
