import { Button, Flex, LoadingOverlay, Table } from "@mantine/core";
import styled from "styled-components";
import {
  AbsolutelyCentered,
  ConfirmModalFrame,
  CustomPagination,
  PageHeader,
} from "../../components";
import { Fragment, useRef, useState } from "react";
import { OrderStatusLabel, User, colors } from "../../constants";
import Papa from "papaparse";
import { useAppData } from "../../contexts/AppContext";
import { PageWrapper } from "../../layout";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import {
  chunk,
  displayPaginationMessage,
  openConfirmationModal,
} from "../../modules";
import { COLLECTIONS, PAGINATION_SIZE } from "../../constants/global";
import moment from "moment";
import { highlightedText } from "../../modules/highlightedText";
import axios from "axios";
import { notifications } from "@mantine/notifications";
import { closeModal } from "@mantine/modals";
import { addSubcollectionDoc } from "../../repositories/firebase";

function PatientLookUp() {
  const {
    patientsData: { savePatient, patients, loading },
    ordersData: { orders },
  } = useAppData();

  const [activePage, setPage] = useState(1);
  const navigate = useNavigate();
  const [uploading, setUploading] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchText, setSearchText] = useState("");
  const [sendingSms, setSendingSms] = useState<string | undefined>();

  const searchFilter = (data: User[], text: string) =>
    data.filter(
      (d) =>
        (d.firstName + " " + d.lastName)
          .toLowerCase()
          .includes(text.toLowerCase()) ||
        moment(d.dateOfBirth).format("MM/DD/YYYY").includes(text.toLowerCase())
    );

  const handleClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target!.result as string;
        parseCsvData(text);
      };
      reader.readAsText(file);
    }
  };
  const parseCsvData = (text: string) => {
    Papa.parse(text, {
      header: true,
      complete: async (results) => {
        let data: User[] = results.data.map((datum: any) => ({
          firstName: datum["First Name"],
          lastName: datum["Last Name"],
          dateOfBirth: datum["DOB"],
          phone: datum["Cell"],
          species: datum["Species"],
          doctor:
            (datum["Dr First Name"] || "") +
            " " +
            (datum["Dr Last Name"] || ""),
        }));
        setUploading(true);

        data = data.filter(
          (datum: any) =>
            !Object.keys(datum).some((key) => datum[key] === undefined)
        );
        const promises = data.map((datum) => savePatient(datum));
        await Promise.all(promises);
        setUploading(false);
      },
    });
  };

  const _patients = chunk(
    patients.filter((p) => orders.find((o) => o.userId === p.id)),
    PAGINATION_SIZE
  );

  let filteredPatients = searchFilter(patients, searchText);

  const confirmSendSms = (data: {
    phone: string;
    uid: string;
    rxNumber: string;
    drName: string;
    name: string;
  }) => {
    openConfirmationModal({
      id: "confirm-sms-modal",
      children: (
        <ConfirmModalFrame
          onConfirm={async () => {
            closeModal("confirm-sms-modal");
            onSendSms(
              data.phone,
              data.uid || "",
              data?.rxNumber || "",
              data.drName,
              data.name
            );
          }}
          onCancel={() => {
            closeModal("confirm-sms-modal");
          }}
          title={`Are you sure you want to send link to this number ${data.phone}?`}
        />
      ),
    });
  };

  const onSendSms = (
    phone: string,
    uid: string,
    rxNumber: string,
    drName: string,
    name: string
  ) => {
    if (!phone)
      return notifications.show({
        title: "Invalid phone number!",
        message: "",
        variant: "error",
      });

    if (!uid || !rxNumber) {
      return notifications.show({
        title: "User/Order does not exist!",
        message: "",
        variant: "error",
      });
    }

    if (!drName) {
      return notifications.show({
        title: "Invalid doctor name!",
        message: "",
        variant: "error",
      });
    }

    setSendingSms(uid);
    const BASE_URL =
      process.env.NODE_ENV === "production"
        ? "https://rx2text.com/"
        : "http://localhost:3000";

    let params = `uid=${uid}&rxNumber=${rxNumber}`;
    let link = `${BASE_URL}?${params}`;

    const cloudFunctionUrl =
      "https://us-central1-art-of-med-text-to-order.cloudfunctions.net/app/sendTwilioSMS";

    let message = `Hey ${name}! This is The Art of Medicine Compounding Pharmacy. It looks like ${drName} prescribed you a medication. For easy and secure ordering, click here: ${link}`;
    console.log("message", message);
    axios
      .post(cloudFunctionUrl, {
        data: {
          phoneNumber: phone,
          message: message,
        },
      })
      .then((response: { data: any }) => {
        notifications.show({
          title: "Message sent successfully",
          message: "",
          variant: "success",
        });
        addSubcollectionDoc(
          COLLECTIONS.USERS,
          uid,
          "user-logs",
          {
            title: `Reminder Message.`,
            description: `Message sent to ${name} to complete their order.`,
          },
          {
            createdAt: true,
          }
        );
      })
      .catch((error: any) => {
        console.error("Error sending SMS:", error);
        notifications.show({
          title: "Error sending SMS!",
          message: "",
          variant: "error",
          color: "red",
        });
      })
      .finally(() => setSendingSms(undefined));
  };

  const rows = (
    searchText ? filteredPatients : _patients[activePage - 1] || []
  )?.map((element) => {
    let order = orders.find((o) => o.userId === element.id);
    return (
      <Table.Tr key={element.id}>
        <TableData>
          <Flex
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(ROUTES.PatientLookUp.path + "/" + element.id);
            }}
          >
            <Flex direction={"column"} align={"start"}>
              <div>
                {highlightedText(
                  element.firstName + " " + element.lastName,
                  searchText
                )}{" "}
              </div>
              <div>
                {highlightedText(
                  moment(element.dateOfBirth).format("MM/DD/YYYY"),
                  searchText
                )}
              </div>
            </Flex>
          </Flex>
        </TableData>
        <TableData>{element.species}</TableData>
        <TableData>{element.phone}</TableData>
        <TableData>{element.doctor}</TableData>
        <TableData>
          {order?.currentStatus.status
            ? OrderStatusLabel[order?.currentStatus.status]
            : ""}
        </TableData>
        <TableData>
          <Button
            loading={element.id === sendingSms}
            onClick={async () => {
              // await new Promise((res, rej) => setTimeout(res, 2000));
              confirmSendSms({
                phone: element.phone,
                uid: element.id || "",
                rxNumber: order?.rxNumber || "",
                drName: element.doctor,
                name: element.firstName,
              });
            }}
          >
            Send Link
          </Button>
        </TableData>
      </Table.Tr>
    );
  });

  return (
    <PageWrapper
      footer={
        <CustomPagination
          activePage={activePage}
          setPage={setPage}
          total={_patients?.length}
          message={displayPaginationMessage(activePage, _patients)}
        />
      }
    >
      <PageHeader
        subtitle={displayPaginationMessage(activePage, _patients)}
        searchProps={{
          placeholder: "Search By Name or Date of Birth",
          value: searchText,
          onChange: (e) => setSearchText(e.target.value),
        }}
        buttons={
          <Fragment>
            {/* <Button onClick={handleClick} radius={"xl"} size="xl">
              Upload Batch
            </Button> */}
            <input
              ref={inputRef}
              type="file"
              accept={".csv"}
              onChange={handleFileChange}
              style={{ display: "none" }}
            />{" "}
          </Fragment>
        }
      />

      <Flex mah={"80vh"} style={{ overflowY: "scroll" }}>
        {!patients.length || !filteredPatients.length ? (
          <AbsolutelyCentered>Nothing found!</AbsolutelyCentered>
        ) : null}
        <Table highlightOnHover withColumnBorders withTableBorder>
          <LoadingOverlay
            visible={uploading || loading}
            loaderProps={{
              children: uploading ? "Uploading data..." : undefined,
            }}
          />

          <Table.Thead
            bg={colors.bg}
            style={{ position: "sticky", top: 0, zIndex: 9 }}
          >
            <Table.Tr>
              <Header>NAME</Header>
              <Header>SPECIES</Header>
              <Header>PHONE NUMBER</Header>
              <Header>DOCTOR</Header>
              <Header>ORDER STATUS</Header>
              <Header>ACTIONS</Header>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Flex>
    </PageWrapper>
  );
}

export default PatientLookUp;

const Header = styled(Table.Th)`
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.03em;
  text-align: left;
  text-transform: uppercase;
`;

const TableData = styled(Table.Td)`
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;
