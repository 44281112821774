import {
  ActionIcon,
  Button,
  Card,
  Flex,
  Image,
  LoadingOverlay,
  Text,
} from "@mantine/core";
import { AdminExt, assets } from "../../constants";
import {
  AbsolutelyCentered,
  ConfirmModalFrame,
  PageHeader,
} from "../../components";
import { Fragment, useCallback, useState } from "react";
import {
  decideAdminName,
  openConfirmationModal,
  openModal,
} from "../../modules";
import AddAdminForm from "./components/AddAdminForm";
import { useAppData } from "../../contexts/AppContext";
import { closeModal, modals } from "@mantine/modals";
import { deleteDocFromCol } from "../../repositories/firebase";
import { COLLECTIONS } from "../../constants/global";
import AdminHistory from "./components/AdminHistory";
import { functions } from "../../firebase";
import { httpsCallable } from "firebase/functions";
import { PageWrapper } from "../../layout";
import { highlightedText } from "../../modules/highlightedText";
import axios from "axios";
import { notifications } from "@mantine/notifications";
const delete_user = httpsCallable(functions, "delete_user");

function AdminAccount() {
  const {
    adminsData: { admins, getAdmins, loading },
    user,
  } = useAppData();
  const [deleting, setDeleting] = useState(false);
  const [searchText, setSearchText] = useState("");

  const onAddMember = () => {
    openModal({
      id: "add-admin",
      children: (
        <AddAdminForm
          onSubmit={() => {
            closeModal("add-admin");
          }}
        />
      ),
    });
  };
  const onEditMember = (admin: AdminExt) => {
    openModal({
      id: "edit-admin",
      children: (
        <AddAdminForm
          initialValues={admin}
          onSubmit={() => {
            closeModal("edit-admin");
          }}
        />
      ),
    });
  };
  const onHistoryClick = (admin: AdminExt) => {
    openModal({
      id: "admin-history",
      children: <AdminHistory logs={admin["admin-logs"] || []} />,
    });
  };

  const deleteAuthUser = (uid: string) => {
    const cloudFunctionUrl =
      "https://us-central1-art-of-med-text-to-order.cloudfunctions.net/app/deleteUser";
    setDeleting(true);
    axios
      .post(cloudFunctionUrl, {
        data: {
          uid,
        },
      })
      .then(async (response: { data: any }) => {
        await deleteDocFromCol(COLLECTIONS.ADMINS, uid);
        getAdmins();
        notifications.show({
          title: "Admin deleted successfully",
          message: "",
          variant: "success",
        });
      })
      .catch((error: any) => {
        console.error("Error deleting Admin:", error);
        notifications.show({
          title: "Error deleting Admin!",
          message: "",
          variant: "error",
          color: "red",
        });
      })
      .finally(() => setDeleting(false));
  };

  const onDeleteMember = useCallback(
    (admin: AdminExt) => {
      openConfirmationModal({
        id: "confirm-delete-modal",
        loading: deleting,
        children: (
          <ConfirmModalFrame
            confirmLoading={deleting}
            onConfirm={async () => {
              if (admin.email === user?.email) {
                return window.alert("Cannot delete yourself!");
              }
              if (admin.id) {
                modals.close("confirm-delete-modal");
                // await new Promise((res, rej) => setTimeout(res, 200));
                deleteAuthUser(admin.id);
              }
            }}
            onCancel={() => {
              closeModal("confirm-delete-modal");
            }}
            title={`Are you sure you want to delete ${admin.name}?`}
          />
        ),
      });
    },
    [deleting]
  );

  const searchFilter = (data: AdminExt[], text: string) =>
    data.filter(
      (d) =>
        d.email?.toLowerCase().includes(text.toLowerCase()) ||
        d.name?.toLowerCase().includes(text.toLowerCase()) ||
        d.phone?.toLowerCase().includes(text.toLowerCase())
    );
  let filteredAdmins = searchFilter(admins, searchText);

  return (
    <PageWrapper>
      <PageHeader
        // subtitle="Showing 1-7 of 40"
        searchProps={{
          placeholder: "Search",
          value: searchText,
          onChange: (e) => setSearchText(e.target.value),
        }}
        buttons={
          <Fragment>
            <Button
              radius={"xl"}
              size="xl"
              leftSection={<Image w={15} h={15} src={assets.icons.plus} />}
              onClick={onAddMember}>
              Add Admin
            </Button>
          </Fragment>
        }
      />
      <Flex style={{ overflowY: "auto" }} h={"100%"} wrap={"wrap"}>
        {!admins.length || !filteredAdmins.length ? (
          <AbsolutelyCentered>Nothing found!</AbsolutelyCentered>
        ) : null}
        <LoadingOverlay visible={loading || deleting} />
        {filteredAdmins.map((a) => {
          return (
            <Card
              m={"lg"}
              flex={1}
              padding="lg"
              h="200px"
              miw={300}
              maw={350}
              radius="md"
              style={{
                boxShadow: "0px 0px 85px -8px #dfdddd",
              }}
              key={a.email}>
              <Flex direction="column" justify={"space-between"} h={"100%"}>
                <Flex justify={"space-between"}>
                  <Text fw={700}>{decideAdminName(a.role)}</Text>
                  <Text ta="right">
                    {a.isActive ? (
                      <Text fw={500}>Active</Text>
                    ) : (
                      <Text fw={500}>Disabled</Text>
                    )}
                  </Text>
                </Flex>
                <Flex align={"center"} justify={"space-between"}>
                  <Flex gap={10} direction={"column"}>
                    <Text fw={500}>{highlightedText(a.name, searchText)}</Text>
                    <Text fw={500}>{highlightedText(a.email, searchText)}</Text>
                    <Text fw={500}>{highlightedText(a.phone, searchText)}</Text>
                  </Flex>
                  <Flex gap={10} direction={"column"}>
                    <ActionIcon
                      onClick={() => onEditMember(a)}
                      variant="transparent"
                      size={"md"}>
                      <Image w={20} src={assets.icons.edit} />
                    </ActionIcon>
                    <ActionIcon
                      onClick={() => onHistoryClick(a)}
                      variant="transparent"
                      size={"md"}>
                      <Image w={20} src={assets.icons.history} />
                    </ActionIcon>
                    <ActionIcon
                      onClick={() => onDeleteMember(a)}
                      variant="transparent"
                      size={"md"}>
                      <Image w={20} src={assets.icons.close} />
                    </ActionIcon>
                  </Flex>
                </Flex>
              </Flex>
            </Card>
          );
        })}
      </Flex>
    </PageWrapper>
  );
}

export default AdminAccount;
