import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { createTheme, MantineProvider } from "@mantine/core";
import { colors } from "./constants";
import AppDataProvider from "./contexts/AppContext";
import { ModalsProvider } from "@mantine/modals";
import { BrowserRouter } from "react-router-dom";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import "@mantine/dates/styles.css";
import { Notifications } from "@mantine/notifications";

const theme = createTheme({
  fontFamily: "Inter, sans-serif",
  headings: { fontFamily: "Inter, sans-serif" },
  primaryColor: "primary-purple",
  colors: {
    "primary-purple": colors.primaryColorTuple,
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
    <BrowserRouter>
      <MantineProvider theme={theme}>
        <AppDataProvider>
          <ModalsProvider>
            <Notifications/>
            <App/>
          </ModalsProvider>
        </AppDataProvider>
      </MantineProvider>
      <div style={{
        position: 'fixed',
        top: '10px',
        left: '50%',
        transform: 'translateX(-50%)',
        padding: '10px',
        borderRadius: '5px',
        color: 'black',
        fontWeight: 'bold',
        zIndex: 9999,
        backgroundColor: process.env.REACT_APP_ENV==='dev' ? 'yellow' : 'green',
        display: process.env.REACT_APP_ENV==='dev' ? 'block' : 'none',
      }}>
        {process.env.REACT_APP_ENV==='dev' ? 'Connected to qcc db' : 'Connected to live db'}
      </div>
    </BrowserRouter>
);
