import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ROUTES, Route } from "../constants/routes";

export function useCurrentRoute() {
  const { pathname } = useLocation();
  const [currentRoute, setCurrentRoute] = useState<Route>(ROUTES.OrderStatus);

  useEffect(() => {
    let routeKey = Object.keys(ROUTES).find((key) =>
      pathname.includes(ROUTES[key].path)
    );

    if (routeKey) {
      setCurrentRoute(ROUTES[routeKey]);
    }
  }, [pathname]);
  return { currentRoute };
}
