/** @format */

import React from "react";
import { modals } from "@mantine/modals";
import { colors } from "../constants";
import { LoadingOverlay } from "@mantine/core";

export const openModal = ({
  children,
  id,
  size = "xl",
  bg = colors.bg,
}: {
  children: React.ReactNode;
  id?: string;
  size?: string;
  bg?: string;
}) => {
  modals.open({
    children,
    onClose: () => {},
    modalId: id,
    closeButtonProps: {
      display: "none",
    },
    centered: true,
    title: "Confirm remove",
    styles: {
      header: {
        display: "none",
      },
      content: {
        padding: 20,
        backgroundColor: bg,
      },
    },
    size,
  });
};

export const openConfirmationModal = ({
  children,
  id,
  loading,
}: {
  children: React.ReactNode;
  id?: string;
  loading?: boolean;
}) => {
  modals.open({
    children: (
      <div>
        <LoadingOverlay visible={loading} />
        {children}
      </div>
    ),
    onClose: () => {},
    modalId: id,
    closeButtonProps: {
      display: "none",
    },
    centered: true,
    title: "Confirm remove",
    styles: {
      header: {
        display: "none",
      },
      content: {
        backgroundColor: colors.bg,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 20,
      },
    },
    radius: "md",
    closeOnClickOutside: !loading,
  });
};
