export enum MessageStatus {
  sent = "sent",
  received = "received",
  read = "read",
}
export enum Specie {
  human = "human",
  pet = "pet",
}

export enum OrderStatus {
  WaitingResponse = "waiting-response",
  Ordered = "ordered",
  Refused = "refused",
  WantsCall = "wants-call",
  Shipped = "shipped",
  Delivered = "delivered",
  Archived = "archived",
  KeepMeOnHold = "keep-me-on-hold",
}

export const OrderStatusLabel = {
  "waiting-response": "Waiting Response",
  ordered: "Ordered",
  refused: "Refused",
  shipped: "Shipped",
  delivered: "Delivered",
  "wants-call": "Wants Call",
  archived: "Archived",
};

export enum BatchRuleConditionType {
  MedicationContains = "medication-contains",
  PriceExceeds = "price-exceeds",
  DosageForm = "dosage-form",
  SPECIE = "species",
  DOB = "dob",
  DrLastName = "drLastName",
  Quantity = "quantity",
}

export enum BatchRuleActionType {
  CheckForPregnantBreastfeeding = "check-for-pregnantbreastfeeding",
  RequireSignature = "requires-signature",
  AutoRefill = "auto-refill",
  Overnight = "overnight",
  MustUseUPS = "must-use-ups",
  ///------------------------///
  FreeShipping = "free-shipping",
  NoPayment = "no-payment",
  ///------------------------///
  HumanMedicineFlavour = "human-medicine-flavour",
  DogTreatFlavour = "dog-treat-flavour",
  DogMedicineFlavour = "dog-medicine-flavour",
  CatTreatFlavour = "cat-treat-flavour",
  CatMedicineFlavour = "cat-medicine-flavour",
  RodentBirdMedicineFlavour = "rodentbird-medicine-flavour",
  Under100Shipping = "under-100-shipping",
  GreaterOrEqual100Shipping = "greater-or-equal-100-shipping",
  OvernightUPS33Shipping = "overnight-ups-33-shipping",
  ///------------------------///
  AltPrice = "alt-price",
  AltQuantity = "alt-quantity",
  ///------------------------///
}

export const RULE_ACTIONS = [
  {
    label: "Check for Pregnant/Breastfeeding",
    slug: BatchRuleActionType.CheckForPregnantBreastfeeding,
  },
  {
    label: "Requires Signature",
    slug: BatchRuleActionType.RequireSignature,
  },
  {
    label: "Auto Refill",
    slug: BatchRuleActionType.AutoRefill,
  },
  // {
  //   label: "Free Shipping",
  //   slug: BatchRuleActionType.FreeShipping,
  // },
  {
    label: "Overnight",
    slug: BatchRuleActionType.Overnight,
  },
  {
    label: "Must Use UPS",
    slug: BatchRuleActionType.MustUseUPS,
  },
  {
    label: "No Payment",
    slug: BatchRuleActionType.NoPayment,
  },

  {
    label: "Human Flavor",
    slug: BatchRuleActionType.HumanMedicineFlavour,
  },

  {
    label: "Dog Flavor",
    slug: BatchRuleActionType.DogMedicineFlavour,
  },

  {
    label: "Dog Treat Flavor",
    slug: BatchRuleActionType.DogTreatFlavour,
  },

  {
    label: "Cat Flavor",
    slug: BatchRuleActionType.CatMedicineFlavour,
  },

  {
    label: "Cat Treat Flavor",
    slug: BatchRuleActionType.CatTreatFlavour,
  },

  {
    label: "Rodent Bird Flavor",
    slug: BatchRuleActionType.RodentBirdMedicineFlavour,
  },
];
