import moment from "moment";
import { Timestamp } from "firebase/firestore";

export const decideAdminName = (role: string) => {
  switch (role) {
    case "admin":
      return "Admin";
    case "super-admin":
      return "Super Admin";
    default:
      return "No Role";
  }
};

export function chunk<T>(array: T[], size: number): T[][] {
  if (!array.length) {
    return [];
  }
  const head = array.slice(0, size);
  const tail = array.slice(size);
  return [head, ...chunk(tail, size)];
}

export const convertTimestamp = (timestamp: Timestamp | undefined) => {
  if (!timestamp) return new Date();
  //extract the seconds and nanos values from your Firestore timestamp object
  const { seconds, nanoseconds } = timestamp || {};
  //combine the seconds and nanos values into a single timestamp in milliseconds
  const milliseconds = seconds * 1000 + nanoseconds / 1e6;
  //use Moment.js to convert the timestamp to a date
  return moment(milliseconds).toDate();
};

export const slugify = (text: string) =>
  text
    .toString()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .toLowerCase()
    .trim()
    .replace(/\s+/g, "-")
    .replace(/[^\w-]+/g, "")
    .replace(/--+/g, "-");

export function displayPaginationMessage(
  activePage: number,
  data: any[][]
): string {
  if (!data || !data.length) return "";
  // Calculate total items
  let totalPages = data.length;
  const totalItems = data.reduce((sum, current) => sum + current.length, 0);

  // Calculate items shown per page (assuming even distribution)
  const itemsPerPage = data[0].length; // taking the first page length as standard

  // Calculate the start and end indices
  let start = (activePage - 1) * itemsPerPage + 1;
  let end = start + itemsPerPage - 1;

  // Adjust the end index on the last page
  if (activePage === totalPages) {
    end = start + data[activePage - 1].length - 1;
  }

  // Format the pagination message
  return `Showing ${start} - ${end} of ${totalItems}`;
}
