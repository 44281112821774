import { onAuthStateChanged, User } from "@firebase/auth";
import { useEffect, useState } from "react";
import { auth } from "../firebase";
import moment from "moment";

export function useAuth() {
  const [user, setUser] = useState<User | null>(null);
  const [authLoading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // check lastLoginTime in storage
        // if that time has passed then logout
        // else set user
        let lastLoginTime = localStorage.getItem("lastLoginTime");

        if (lastLoginTime) {
          const lastLoginTimeDate = moment(lastLoginTime);
          const now = moment();

          if (now.isAfter(lastLoginTimeDate, "day")) {
            auth.signOut();
            setUser(null);
            setLoading(false);
            return;
          } else {
            setUser(user);
          }
        } else {
          setUser(user);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  return { user, authLoading };
}
