/** @format */

const assetPath = "/assets/images/";
const iconsPath = "/assets/icons/";
const images = {
  logo: `${assetPath}artofmedicine-logo.svg`,
  medicationCream: `${assetPath}medication-cream.svg`,
  medicationTablet: `${assetPath}medication-tablet.svg`,
};
const icons = {
  edit: `${iconsPath}edit.png`,
  editWhite: `${iconsPath}edit-white.png`,
  history: `${iconsPath}history.png`,
  historyWhite: `${iconsPath}historyIconWhite.png`,
  plus: `${iconsPath}plus.png`,
  close: `${iconsPath}close.png`,
  dots: `${iconsPath}dots.png`,
  archive: `${iconsPath}archive.png`,
  verifiedProfile: `${iconsPath}verified_profile.svg`,
  leftToggle: `${iconsPath}left_toggle.svg`,
};

const assets = {
  images,
  icons,
};

export default assets;
