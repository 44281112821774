import { ROUTES } from "./routes";

export const HIDE_LAYOUT_FOR_ROUTES = [ROUTES.Login.path];
export const HIDE_SIDEBAR_FOR_ROUTES: string[] = [];

export enum COLLECTIONS {
  USERS = "users",
  ORDERS = "orders",
  ADMINS = "admins",
  PATIENTS = "patients",
  BATCH_RULES = "batch-rules",
  BATCH_RULE_ACTIONS = "batch-rule-actions",
  MESSAGE_TEMPLATES = "message-templates",
  BATCH_PREVIEW = "batch-preview",
  MESSAGE_THREAD = "message-thread",
}

export function capitalizeWords(input: any) {
  return input
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join(" ");
}

export function formatPhoneNumber(phoneNumber) {
  if (!phoneNumber) return;
  // Remove non-numeric characters
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");

  // Check if the length is 10 and prepend "+1"
  if (cleanedPhoneNumber.length === 10) {
    return `+1${cleanedPhoneNumber}`;
  }
  // Check if the length is 11 and starts with "1", then prepend "+"
  else if (
    cleanedPhoneNumber.length === 11 &&
    cleanedPhoneNumber.startsWith("1")
  ) {
    return `+${cleanedPhoneNumber}`;
  }

  // Return the cleaned phone number as is if none of the above conditions are met
  return cleanedPhoneNumber;
}

export const PAGINATION_SIZE = 10;
