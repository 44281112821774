/** @format */

import { useEffect, useState } from "react";
import { Order, OrderStatus, UseOrders } from "../constants";
import { getAllDocs, upsertDoc } from "../repositories/firebase";
import { COLLECTIONS } from "../constants/global";
import moment from "moment";
import { convertTimestamp } from "../modules";

export const useOrders = (): UseOrders => {
  const [orders, setOrders] = useState<Order[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getOrders = async () => {
    try {
      const _orders = await getAllDocs<Order>(COLLECTIONS.ORDERS);
      let tempOrders = _orders.map((o) => {
        if (
          o.currentStatus.status === OrderStatus.WaitingResponse &&
          moment().diff(
            moment(convertTimestamp(o?.createdAt as any)),
            "hours"
          ) >= 48
        ) {
          return {
            ...o,
            currentStatus: {
              ...o.currentStatus,
              status: OrderStatus.Refused,
            },
          };
        } else return o;
      });
      setOrders(tempOrders);

      return _orders;
    } catch (error) {
      console.log("Error getting orders!", error);
      return [];
    }
  };

  const saveOrder = async (
    _order: Order,
    options: { refetch: boolean } = { refetch: true }
  ): Promise<any> => {
    const { success, code, message } = await upsertDoc<Order>(
      COLLECTIONS.ORDERS,
      {
        ..._order,
      },
      _order?.id
    );
    if (success) {
      console.log("Order saved successfully!");
      if (options?.refetch) {
        getOrders();
      }
    } else {
      console.log("Error saving Order!", message, code);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getOrders();
      setLoading(false);
    })();
  }, []);

  return {
    orders,
    loading,
    getOrders,
    saveOrder,
  };
};
