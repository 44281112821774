import { Button, Flex, TextInput, Textarea, Title } from "@mantine/core";
import { useForm } from "@mantine/form";
import { notifications } from "@mantine/notifications";
import React, { useState } from "react";
import { useAppData } from "../../../contexts/AppContext";
import { User } from "../../../constants";
import { input_styles } from "../../../constants/theme";

function EditInternalNote({
  patient,
  onSubmit,
}: {
  patient: User;
  onSubmit: () => void;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    patientsData: { savePatient },
  } = useAppData();
  const form = useForm<any>({
    initialValues: {
      internalNotes: patient?.internalNotes || "",
    },
  });

  const handleRefund = async (values: any) => {
    setIsSubmitting(true);

    await savePatient(
      {
        internalNotes: values?.internalNotes,
        id: patient?.id,
      } as any,
      { refetch: true }
    );
    setIsSubmitting(false);
    onSubmit();
  };
  return (
    <form onSubmit={form.onSubmit((values) => handleRefund(values))}>
      <Flex direction={"column"} gap={"lg"}>
        <Title size="xl"></Title>

        <Textarea
          rows={10}
          radius={"md"}
          styles={input_styles}
          size="xl"
          placeholder="Internal Notes"
          label="Internal Notes"
          name="internalNotes"
          {...form.getInputProps("internalNotes")}
        />

        <Button
          type="submit"
          loading={isSubmitting}
          size="lg"
          radius={"xl"}
          style={{ alignSelf: "center" }}
        >
          Save
        </Button>
      </Flex>
    </form>
  );
}

export default EditInternalNote;
