import { useState } from "react";
import { Flex, Space, Button } from "@mantine/core";
import styled from "styled-components";
import { IAdminLog } from "../../../constants";
import moment from "moment";
import { convertTimestamp } from "../../../modules";

function AdminHistory({ logs = [] }: { logs?: IAdminLog[] }) {
  const [currentPage, setCurrentPage] = useState(1);
  const logsPerPage = 10; // Number of logs to display per page

  // Sort logs by createdAt timestamp in descending order
  const sortedLogs = [...logs].sort((a, b) => {
    const timeA = moment(convertTimestamp(a.createdAt as any));
    const timeB = moment(convertTimestamp(b.createdAt as any));
    return timeB.diff(timeA);
  });

  // Calculate the indices for pagination
  const indexOfLastLog = currentPage * logsPerPage;
  const indexOfFirstLog = indexOfLastLog - logsPerPage;
  const currentLogs = sortedLogs.slice(indexOfFirstLog, indexOfLastLog);

  // Handle page change
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  return (
    <Flex mih={300} direction={"column"}>
      <Title>History</Title>
      <Space h={"xl"} />
      <Flex direction={"column"} gap={15} style={{ overflowY: "scroll" }}>
        {currentLogs.map((log) => {
          return (
            <Flex justify={"space-between"} key={log.title}>
              <Subtext>
                {log.title} - {log.description}
              </Subtext>
              <Subtext>
                {moment(convertTimestamp(log.createdAt as any)).format(
                  "MMM, DD hh:mm a"
                )}
              </Subtext>
            </Flex>
          );
        })}
      </Flex>
      <Space h={"xl"} />
      {sortedLogs.length > logsPerPage && (
        <Flex justify={"center"} gap={10}>
          {Array.from({
            length: Math.ceil(sortedLogs.length / logsPerPage),
          }).map((_, index) => (
            <Button
              key={index + 1}
              onClick={() => paginate(index + 1)}
              variant={index + 1 === currentPage ? "filled" : "outline"}>
              {index + 1}
            </Button>
          ))}
        </Flex>
      )}
    </Flex>
  );
}

export default AdminHistory;

const Title = styled.div`
  font-family: Inter;
  font-size: 24px;
  font-weight: 800;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
`;

const Subtext = styled.div`
  font-family: Inter;
  font-size: 17px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;
