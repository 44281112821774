/** @format */

import { Flex, LoadingOverlay, Table } from "@mantine/core";
import {
  AbsolutelyCentered,
  CustomPagination,
  PageHeader,
} from "../../components";
import { PageWrapper } from "../../layout";
import { Order, OrderStatus, User, colors } from "../../constants";
import styled from "styled-components";
import { useAppData } from "../../contexts/AppContext";
import { useState } from "react";
import {
  chunk,
  convertTimestamp,
  displayPaginationMessage,
} from "../../modules";
import { PAGINATION_SIZE } from "../../constants/global";
import { highlightedText } from "../../modules/highlightedText";
import moment from "moment";

const searchFilter = (orders: Order[], patients: User[], text: string) => {
  return orders.filter((order) => {
    let patient = patients.find((p) => p.id === order.userId);
    if (
      (patient?.firstName + " " + patient?.lastName)
        .toLowerCase()
        .includes(text.toLowerCase()) ||
      moment(patient?.dateOfBirth)
        .format("MM/DD/YYYY")
        .includes(text.toLowerCase())
    ) {
      return true;
    } else return false;
  });
};

function Archive() {
  const {
    ordersData: { orders, loading },
    patientsData: { patients },
  } = useAppData();
  const [searchText, setSearchText] = useState("");
  const [activePage, setPage] = useState(1);

  let archivedOrders = orders.filter(
    (o) => o?.currentStatus?.status === OrderStatus.Archived
  );
  const _orders = chunk(archivedOrders, PAGINATION_SIZE);

  let filteredOrders = searchFilter(archivedOrders, patients, searchText);

  const rows = (
    searchText ? filteredOrders : _orders[activePage - 1] || []
  )?.map((order) => {
    // let order = orders.find((o) => o.userId === element.id);
    let patient = patients.find((p) => p.id === order.userId);
    return (
      <Table.Tr key={order.id}>
        <TableData>
          <Flex>
            <Flex direction={"column"} align={"start"}>
              <div>
                {highlightedText(
                  patient?.firstName + " " + patient?.lastName,
                  searchText
                )}{" "}
              </div>
              <div>
                {highlightedText(
                  moment(patient?.dateOfBirth).format("MM/DD/YYYY"),
                  searchText
                )}
              </div>
            </Flex>
          </Flex>
        </TableData>
        <TableData>{patient?.email}</TableData>
        <TableData>{patient?.phone}</TableData>
        <TableData>{patient?.doctor}</TableData>
        <TableData>
          {moment(convertTimestamp(order?.currentStatus?.date as any)).format(
            "MM/DD/YYYY hh:mm"
          )}
        </TableData>
      </Table.Tr>
    );
  });

  return (
    <PageWrapper
      footer={
        <CustomPagination
          activePage={activePage}
          setPage={setPage}
          total={_orders?.length}
          message={displayPaginationMessage(activePage, _orders)}
        />
      }
    >
      <PageHeader
        subtitle={displayPaginationMessage(activePage, _orders)}
        searchProps={{
          placeholder: "Search By Name or Date of Birth",
          value: searchText,
          onChange: (e) => setSearchText(e.target.value),
        }}
        // buttons={""}
      />

      <Flex mah={"80vh"} style={{ overflowY: "scroll" }}>
        {!archivedOrders.length || !filteredOrders.length ? (
          <AbsolutelyCentered>Nothing found!</AbsolutelyCentered>
        ) : null}
        <Table highlightOnHover withColumnBorders withTableBorder>
          <LoadingOverlay
            visible={loading}
            // loaderProps={{
            //   children: uploading ? "Uploading data..." : undefined,
            // }}
          />

          <Table.Thead
            bg={colors.bg}
            style={{ position: "sticky", top: 0, zIndex: 9 }}
          >
            <Table.Tr>
              <Header>NAME</Header>
              <Header>EMAIL</Header>
              <Header>PHONE NUMBER</Header>
              <Header>DOCTOR</Header>
              <Header>ARCHIVED</Header>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>{rows}</Table.Tbody>
        </Table>
      </Flex>
    </PageWrapper>
  );
}

export default Archive;

const Header = styled(Table.Th)`
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.03em;
  text-align: left;
  text-transform: uppercase;
`;

const TableData = styled(Table.Td)`
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
`;
