/** @format */

import { Fragment, useEffect, useState } from "react";
import { PageWrapper } from "../../layout";
import {
	AbsolutelyCentered,
	ConfirmModalFrame,
	PageHeader,
} from "../../components";
import {
	ActionIcon,
	Button,
	Card,
	Flex,
	Image,
	LoadingOverlay,
	TextInput,
	Textarea,
} from "@mantine/core";
import styled, { css } from "styled-components";
import { MessageTemplate, assets, colors } from "../../constants";
import { useAppData } from "../../contexts/AppContext";
import { IconX } from "@tabler/icons-react";
import { useDisclosure } from "@mantine/hooks";
import { input_styles } from "../../constants/theme";
import { useForm } from "@mantine/form";
import { openConfirmationModal } from "../../modules";
import { COLLECTIONS } from "../../constants/global";
import { closeModal } from "@mantine/modals";
import { deleteDocFromCol } from "../../repositories/firebase";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";

function MessageTemplates() {
	const navigate = useNavigate();
	const {
		templatesData: { loading, templates, saveTemplate, getTemplates },
		user,
	} = useAppData();
	const [selectedTemplate, setSelectedTemplate] = useState<
		MessageTemplate | undefined
	>();
	const [savingTemplate, setSavingTemplate] = useState(false);

	const [editable, { open: makeEditable, close: makeUnEditable }] =
		useDisclosure();

	const template = useForm<any>({
		initialValues: {
			title: selectedTemplate?.title || "",
			content: selectedTemplate?.content || "",
		},
		validate: {
			title: (value) => (value === "" ? "Title required" : null),
			content: (value) => (value === "" ? "Content required" : null),
		},
	});

	useEffect(() => {
		template.setValues({
			title: selectedTemplate?.title || "",
			content: selectedTemplate?.content || "",
		});
	}, [selectedTemplate]);

	useEffect(() => {
		setSelectedTemplate(templates![0]);
	}, [templates]);

	const handleSaveTemplate = async (values: any) => {
		setSavingTemplate(true);
		await saveTemplate({
			title: values.title,
			content: values.content,
			createdBy: user?.uid,
			id: selectedTemplate?.id,
		});
		setSavingTemplate(false);
		makeUnEditable();
	};

	const onRemoveTemplate = (template?: MessageTemplate) => {
		openConfirmationModal({
			id: "confirm-delete-modal",
			children: (
				<ConfirmModalFrame
					onConfirm={async () => {
						if (template?.id) {
							await deleteDocFromCol(
								COLLECTIONS.MESSAGE_TEMPLATES,
								template.id
							);
							closeModal("confirm-delete-modal");
							getTemplates();
						}
					}}
					onCancel={() => {
						closeModal("confirm-delete-modal");
					}}
					title={`Are you sure you want to delete template "${template?.title}"?`}
				/>
			),
		});
	};

	return (
		<PageWrapper
			footer={
				<Flex
					style={{ cursor: "pointer", marginTop: "10px" }}
					onClick={() => {
						navigate(ROUTES.Messaging.path);
					}}
					align={"center"}
					gap={15}>
					<Image radius={"xl"} src={assets.icons.leftToggle} />
					<Back>Back to Messages </Back>
				</Flex>
			}>
			<PageHeader
				title="Create Template"
				searchProps={{
					placeholder: "Search",
				}}
			/>
			<form
				style={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
					gap: 20,
					overflow: "auto",
				}}
				onSubmit={template.onSubmit((values) => handleSaveTemplate(values))}>
				<Flex h={"90%"} gap={20}>
					<Card
						className="card-shadow"
						style={{ overflowY: "auto" }}
						shadow="lg"
						padding="lg"
						radius="lg"
						withBorder
						flex={2}>
						<LoadingOverlay visible={loading} />
						<Flex direction={"column"} flex={2} gap={20}>
							<Title>Select Template</Title>
							<Flex direction={"column"} gap={10}>
								{templates.length ? (
									<Fragment>
										{templates.map((template) => (
											<TemplateName
												onClick={() => {
													makeUnEditable();
													setSelectedTemplate(template);
												}}
												key={template.id}
												aria-selected={template.id === selectedTemplate?.id}>
												{template.title}
											</TemplateName>
										))}
									</Fragment>
								) : (
									<AbsolutelyCentered>Nothing here</AbsolutelyCentered>
								)}
							</Flex>
						</Flex>
					</Card>
					<Card
						className="card-shadow"
						// style={{ overflowY: "auto" }}
						shadow="lg"
						padding="lg"
						radius="lg"
						withBorder
						flex={4}
						p={"xl"}>
						{editable ? (
							<Flex direction={"column"} gap={10}>
								<ActionIcon
									onClick={(e) => {
										e.preventDefault();
										makeUnEditable();
									}}
									style={{ alignSelf: "flex-end" }}>
									<IconX size={24} />
								</ActionIcon>
								<TextInput
									radius={"md"}
									styles={input_styles}
									size="xl"
									label="Full Name"
									placeholder="Template name"
									{...template?.getInputProps("title")}
								/>
								<Textarea
									placeholder="Content"
									radius={"md"}
									styles={input_styles}
									size="xl"
									label="Content"
									rows={4}
									{...template?.getInputProps("content")}
								/>
							</Flex>
						) : (
							<Fragment>
								{selectedTemplate ? (
									<Fragment>
										<SelectedTemplate>
											{" "}
											{selectedTemplate.title}
										</SelectedTemplate>
										<TemplateContent>
											{selectedTemplate.content}
										</TemplateContent>
									</Fragment>
								) : (
									<AbsolutelyCentered>
										Select a template to view or create a new one
									</AbsolutelyCentered>
								)}
							</Fragment>
						)}
					</Card>
				</Flex>

				<Flex justify={"space-between"} align={"center"}>
					<Flex align={"center"} gap={10}>
						<Button
							onClick={(e) => {
								e.preventDefault();
								makeEditable();
								setSelectedTemplate(undefined);
							}}
							type="button"
							size="lg"
							radius={"xl"}>
							Create Template
						</Button>
						<ActionIcon
							disabled={selectedTemplate === undefined}
							onClick={() => onRemoveTemplate(selectedTemplate)}
							size={"xl"}
							radius={"xl"}>
							<IconX size={24} />
						</ActionIcon>
					</Flex>

					{editable ? (
						<Button
							type="submit"
							size="lg"
							radius={"xl"}
							loading={savingTemplate}>
							Save Template
						</Button>
					) : (
						<Button
							onClick={(e) => {
								e.preventDefault();
								makeEditable();
							}}
							type="button"
							size="lg"
							radius={"xl"}>
							Edit Template
						</Button>
					)}

					<div />
				</Flex>
			</form>
		</PageWrapper>
	);
}

export default MessageTemplates;

const Title = styled.div`
	//styleName: Mobile Header 2;
	font-family: Manrope;
	font-size: 23px;
	font-weight: 800;
	line-height: 31.42px;
	text-align: left;
`;

const SelectedTemplate = styled.div`
	//styleName: Mobile Header 2;
	font-family: Manrope;
	font-size: 23px;
	font-weight: 800;
	line-height: 31.42px;
	text-align: left;

	padding: 10px 15px;
	background-color: ${colors.bg};
	margin-bottom: 15px;
	border-radius: 5px;
`;

const TemplateName = styled.div`
	font-family: Manrope;
	font-size: 23px;
	font-weight: 500;
	line-height: 31.42px;
	text-align: left;

	padding: 10px 10px;
	border-radius: 5px;
	user-select: none;
	cursor: pointer;

	&:hover {
		background-color: #fae7d0;
	}

	${(props) =>
		props["aria-selected"]
			? css`
					background-color: #fbe4c7;
			  `
			: ``}
`;
const TemplateContent = styled.div`
	font-family: Manrope;
	font-size: 23px;
	font-weight: 400;
	line-height: 31.42px;
	text-align: left;
`;

const Back = styled.div`
	font-family: Manrope;
	font-size: 18px;
	font-weight: 700;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;
`;
