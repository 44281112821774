/** @format */

import { useLocation } from "react-router";
import { Navigate, Outlet } from "react-router-dom";
import { useAppData } from "../contexts/AppContext";

const ProtectedRoutes = () => {
	const { user } = useAppData();
	const location = useLocation();

	return user ? (
		<Outlet />
	) : (
		<Navigate to="/login" replace state={{ from: location }} />
	);
};

export default ProtectedRoutes;
