/** @format */

import { Flex } from "@mantine/core";
import { colors } from "../constants";
import styled from "styled-components";

interface Props {
	name: string;
	isActive: boolean;
	onClick: () => void;
}

function SidebarItem({ name, isActive, onClick }: Props) {
	return (
		<Flex
			w={"100%"}
			c={isActive ? "white" : undefined}
			bg={isActive ? colors.primaryColor : undefined}
			style={{
				fontWeight: "700",
				fontSize: 20,
				padding: 15,
				cursor: "pointer",
			}}
			onClick={onClick}>
			<Text>
				{name}
				{name === "Messaging" && <span className="cnt_holder" style={{paddingLeft:5}}></span>}
			</Text>
		</Flex>
	);
}

export default SidebarItem;

const Text = styled.div`
	//styleName: Paragragh 1 - Bold;
	font-family: DM Sans;
	font-size: 22px;
	font-weight: 700;
	line-height: 29px;
	letter-spacing: 0em;
	text-align: left;
`;
