import { PageHeader, CustomPagination } from "../../components";
import styled from "styled-components";
import { Flex } from "@mantine/core";
import {
  Order,
  OrderStatus as OrderStatusEnum,
  User,
  colors,
} from "../../constants";
import { PageWrapper } from "../../layout";
import { useAppData } from "../../contexts/AppContext";
import OrderItem from "./components/OrderItem";
import { useState } from "react";
import moment from "moment";
import { convertTimestamp } from "../../modules";

const searchFilter = (orders: Order[], patients: User[], text: string) => {
  return orders.filter((order) => {
    let patient = patients.find((p) => p.id === order.userId);
    if (
      (patient?.firstName + " " + patient?.lastName)
        .toLowerCase()
        .includes(text.toLowerCase()) ||
      moment(patient?.dateOfBirth)
        .format("MM/DD/YYYY")
        .includes(text.toLowerCase())
    ) {
      return true;
    } else return false;
  });
};

function OrderStatus() {
  const {
    ordersData: { orders: _orders },
    patientsData: { patients },
  } = useAppData();
  const [searchText, setSearchText] = useState("");

  let orders = searchFilter(_orders, patients, searchText);

  let waitingOrders = orders.filter(
    (o) => o.currentStatus.status === OrderStatusEnum.WaitingResponse
  );
  let orderedOrders = orders.filter(
    (o) => o.currentStatus.status === OrderStatusEnum.Ordered
  );
  let noResponseOrders = orders.filter(
    (o) => o.currentStatus.status === OrderStatusEnum.Refused
  );
  let wantCallOrders = orders.filter(
    (o) => o.currentStatus.status === OrderStatusEnum.WantsCall
  );
  let onHoldOrders = orders.filter(
    (o) => o.currentStatus.status === OrderStatusEnum.KeepMeOnHold
  );


  orderedOrders = orderedOrders.sort((a, b) => {
    if (!a.orderDate && !b.orderDate) return 0;
    if (!a.orderDate) return -1;
    if (!b.orderDate) return 1;
    return a.orderDate.seconds - b.orderDate.seconds;
  });


  const renderOrders = (orders: Order[]) => {
    return orders.map((order) => {
      return <OrderItem order={order} searchText={searchText} />;
    });
  };

  return (
    <PageWrapper>
      <PageHeader
        searchProps={{
          placeholder: "Search",
          value: searchText,
          onChange: (e) => setSearchText(e.target.value),
        }}
      />

      <Flex
        direction={"column"}
        gap={"md"}
        style={{ overflowY: "scroll", position: "relative" }}>
        <Flex
          bg={colors.bg}
          p={10}
          style={{ position: "sticky", top: 0, zIndex: 9 }}>
          <Flex flex={1}>
            <Header>WAITING ON RESPONSE</Header>
          </Flex>
          <Flex flex={1}>
            <Header>ORDERED</Header>
          </Flex>
          <Flex flex={1}>
            <Header>NO RESPONSE</Header>
          </Flex>
          <Flex flex={1}>
            <Header>Refused</Header>
          </Flex>
          <Flex flex={1}>
            <Header>WANTS A CALL</Header>
          </Flex>
        </Flex>

        <Flex gap={"md"}>
          <Flex flex={1} direction={"column"} gap={"md"}>
            {renderOrders(waitingOrders)}
          </Flex>
          <Flex flex={1} direction={"column"} gap={"md"}>
            {renderOrders(orderedOrders)}
          </Flex>
          <Flex flex={1} direction={"column"} gap={"md"}>
            {renderOrders(noResponseOrders)}
          </Flex>
          <Flex flex={1} direction={"column"} gap={"md"}>
            {renderOrders(onHoldOrders)}
          </Flex>
          <Flex flex={1} direction={"column"} gap={"md"}>
            {renderOrders(wantCallOrders)}
          </Flex>
        </Flex>
      </Flex>
    </PageWrapper>
  );
}

export default OrderStatus;

const Header = styled.div`
  text-transform: uppercase;
  font-weight: 700;
`;
