import React, { PropsWithChildren } from "react";
import styled from "styled-components";

function AbsolutelyCentered({ children }: PropsWithChildren<{}>) {
  return <Center>{children}</Center>;
}

export default AbsolutelyCentered;

const Center = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
