import { ActionIcon, Button, Card, Flex } from "@mantine/core";
import { IconPencil } from "@tabler/icons-react";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled, { css } from "styled-components";
import { ROUTES } from "../../../constants/routes";
import { MessageTemplate } from "../../../constants";
import { useAppData } from "../../../contexts/AppContext";
import { AbsolutelyCentered } from "../../../components";

interface Props {
  onSelectTemplate: (template: any) => void;
}
function InsertTemplate({ onSelectTemplate }: Props) {
  const navigate = useNavigate();
  const {
    templatesData: { templates },
  } = useAppData();
  const [selectedTemplate, setSelectedTemplate] = useState<
    MessageTemplate | undefined
  >();

  useEffect(() => {
    setSelectedTemplate(templates![0]);
  }, [templates]);



  return (
    <Flex gap={15} mih={400}>
      <Flex direction={"column"} flex={2} gap={20}>
        <Title>Select Template</Title>
        <Flex direction={"column"} gap={10}>
          {templates.length ? (
            <Fragment>
              {templates.map((template) => (
                <TemplateName
                  onClick={() => {
                    setSelectedTemplate(template);
                  }}
                  key={template.id}
                  aria-selected={template.id === selectedTemplate?.id}
                >
                  {template.title}
                </TemplateName>
              ))}
            </Fragment>
          ) : (
            <AbsolutelyCentered>Nothing here</AbsolutelyCentered>
          )}
        </Flex>
      </Flex>
      <Flex flex={3} direction={"column"} gap={10}>
        <Card flex={1}>
          <Fragment>
            {selectedTemplate ? (
              <TemplateContent>{selectedTemplate.content}</TemplateContent>
            ) : (
              <AbsolutelyCentered>
                Select a template to view or create a new one
              </AbsolutelyCentered>
            )}
          </Fragment>
        </Card>
        <Flex gap={10} align={"center"} style={{ alignSelf: "flex-end" }}>
          <ActionIcon
            onClick={() => {
              navigate(ROUTES.MessageTemplates.path);
              onSelectTemplate({});
            }}
            size={"xl"}
            radius={"xl"}
          >
            <IconPencil size={24} />
          </ActionIcon>
          <Button
            // type="submit"
            size="lg"
            radius={"xl"}
            onClick={() => onSelectTemplate(selectedTemplate?.content)}
            // loading={savingRule}
          >
            Select Template
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default InsertTemplate;

const Title = styled.div`
  //styleName: Mobile Header 2;
  font-family: Manrope;
  font-size: 23px;
  font-weight: 800;
  line-height: 31.42px;
  text-align: left;
`;
const TemplateName = styled.div`
  font-family: Manrope;
  font-size: 23px;
  font-weight: 500;
  line-height: 31.42px;
  text-align: left;

  padding: 10px 10px;
  border-radius: 5px;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: #fae7d0;
  }

  ${(props) =>
    props["aria-selected"]
      ? css`
          background-color: #fbe4c7;
        `
      : ``}
`;
const TemplateContent = styled.div`
  font-family: Manrope;
  font-size: 23px;
  font-weight: 400;
  line-height: 31.42px;
  text-align: left;
`;
