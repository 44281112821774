import { MantineColorsTuple } from "@mantine/core";

const primaryColorTuple: MantineColorsTuple = [
  "#f6edff",
  "#e6d6fa",
  "#c9aaf1",
  "#ab7be8",
  "#9154e0",
  "#813adc",
  "#792eda",
  "#6721c2",
  "#5c1cae",
  "#4f1599",
];

let primaryColor = "#6E23CF";
let bg = "#f8f3f0";

const colors = {
  primaryColorTuple,
  primaryColor,
  bg,
};

export const input_styles = {
  input: {
    borderColor: "#6E23CF",
    borderWidth: "3px",
  },
};

export default colors;
