import {
  Button,
  Checkbox,
  Flex,
  Image,
  Input,
  LoadingOverlay,
  Modal,
  Table,
  TextInput,
  Title,
  rem,
} from "@mantine/core";
import { BatchRuleActionType, User, assets, colors } from "../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { ROUTES } from "../../constants/routes";
import styled from "styled-components";
import { useAppData } from "../../contexts/AppContext";
import moment from "moment";
import { highlightedText } from "../../modules/highlightedText";
import axios from "axios";
import { addSubcollectionDoc } from "../../repositories/firebase";
import { COLLECTIONS } from "../../constants/global";

const sendSms = ({ uid, rxNumber, drName, phone, name }) => {
  const BASE_URL =
    process.env.NODE_ENV === "production"
      ? "https://rx2text.com/"
      : "http://localhost:3000";

  let params = `uid=${uid}&rxNumber=${rxNumber}`;
  let link = `${BASE_URL}?${params}`;

  const cloudFunctionUrl =
    "https://us-central1-art-of-med-text-to-order.cloudfunctions.net/app/sendTwilioSMS";

  let message = `Hey ${name}! This is The Art of Medicine Compounding Pharmacy. It looks like ${drName} prescribed you a medication. For easy and secure ordering, click here: ${link}`;
  axios
    .post(cloudFunctionUrl, {
      data: {
        phoneNumber: phone,
        message: message,
      },
    })
    .then((response) => {
      console.error("SMS sent successfully", response.data);
      addSubcollectionDoc(
        COLLECTIONS.USERS,
        uid,
        "user-logs",
        {
          title: `Initial Message.`,
          description: `Message sent to ${name} to complete their order.`,
        },
        {
          createdAt: true,
        }
      );
    })
    .catch((error: any) => {
      console.error("Error sending SMS:", error);
    });
};

function BatchPreview() {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [batchPreview, setBatchPreview] = useState<any[]>([]);

  const {
    batchPreviewData: { saveBatchPreview, getBatchPreview, loading },
    patientsData: { savePatient, getPatients },
    ordersData: { saveOrder, getOrders, orders: _orders },
  } = useAppData();

  const _batchPreview = location.state?.data || [];

  const update = location.state?.update || false;

  useEffect(() => {
    setBatchPreview(_batchPreview);
  }, [location.state]);

  useEffect(() => {
    setOpen(true);
  }, []);

  const handleUpload = async () => {
    setUploading(true);
    if (update) {
      const ordersPromises = _orders.map((order: any) => {
        let updatedResult = batchPreview.find(
          (r) => r.rxNumber === order.rxNumber
        );
        let medications = order.medications.map((o) => {
          if (o.itemName === updatedResult.med) {
            return {
              ...o,
              isAutoRefillVisible:
                updatedResult?.metadata[BatchRuleActionType.AutoRefill] ||
                false,
              alt_price: updatedResult.alt_price,
              alt_qty: updatedResult.alt_qty,
              overnight:
                updatedResult?.metadata[BatchRuleActionType.Overnight] ||
                updatedResult?.metadata[
                  BatchRuleActionType.OvernightUPS33Shipping
                ] ||
                false,
              metadata: updatedResult?.metadata || {},
            };
          } else return o;
        });
        return saveOrder(
          {
            ...order,
            medications: medications,
            metadata: {
              ...updatedResult?.metadata,
            },
          },
          { refetch: false }
        );
      });

      await Promise.all(ordersPromises);
    } else {
      // get unique patients by firstName, lastName, dob and phone
      const uniqueIdentifiers = new Set();
      const uniquePatients = batchPreview.filter((patient) => {
        const uniqueKey = `${patient.firstName}|${patient.lastName}|${patient.dateOfBirth}|${patient.phone}`;
        if (!uniqueIdentifiers.has(uniqueKey)) {
          uniqueIdentifiers.add(uniqueKey);
          return true;
        }
        return false;
      });
      let patients: User[] = uniquePatients.map((datum: any) => ({
        firstName: datum.firstName,
        lastName: datum.lastName,
        dateOfBirth: datum.dateOfBirth,
        phone: datum.phone,
        species: datum.species,
        doctor: datum.doctor,
      }));

      const patientsPromises = patients.map((datum: any) =>
        savePatient(datum, { refetch: false })
      );
      const savedPatients = await Promise.all(patientsPromises);
      let ordersMap = {};

      uniquePatients.forEach((p) => {
        const uniqueKey = `${p.firstName}|${p.lastName}|${p.dateOfBirth}|${p.phone}`;
        let patientSaved = savedPatients.find(
          (patient) => patient.key === uniqueKey
        );
        if (p.phone && p.rxNumber && p.doctor && patientSaved.id) {
          sendSms({
            uid: patientSaved.id,
            rxNumber: p.rxNumber,
            drName: p.doctor,
            phone: p.phone,
            name: p.firstName,
          });
        }
      });

      batchPreview.forEach((datum) => {
        const uniqueKey = `${datum.firstName}|${datum.lastName}|${datum.dateOfBirth}|${datum.phone}`;
        let patientSaved = savedPatients.find(
          (patient) => patient.key === uniqueKey
        );

        // Create a new medication object from the current datum
        const newMedication = {
          itemName: datum.med,
          price: datum.price,
          quantity: datum.qty,
          dosageForm: datum.dosage_form,

          alt_price: datum?.alt_price,
          alt_qty: datum?.alt_qty,
          isAutoRefillVisible:
            datum?.metadata[BatchRuleActionType.AutoRefill] || false,
          overnight:
            datum?.metadata[BatchRuleActionType.Overnight] ||
            datum?.metadata[BatchRuleActionType.OvernightUPS33Shipping] ||
            false,
          metadata: datum?.metadata || {},
        };

        let reqSignature = false;
        if (datum?.metadata[BatchRuleActionType.RequireSignature]) {
          reqSignature = datum?.metadata[BatchRuleActionType.RequireSignature];
        }

        if (ordersMap[uniqueKey]) {
          // If the order already exists, push the new medication to its medications array
          ordersMap[uniqueKey].medications.push(newMedication);
          ordersMap[uniqueKey].metadata[BatchRuleActionType.RequireSignature] =
            reqSignature;
        } else {
          // Otherwise, create a new order and add it to the orders map
          ordersMap[uniqueKey] = {
            userId: patientSaved?.id || "",
            currentStatus: {
              status: datum.orderStatus,
              date: datum.orderDate,
            },
            orderDate: datum.orderDate,
            //! TODO: remove  isAutoRefillVisible from here
            isAutoRefillVisible:
              datum?.metadata[BatchRuleActionType.AutoRefill] || false,
            medications: [newMedication],
            rxNumber: datum.rxNumber,
            createdAt: new Date(),
            metadata: datum.metadata || {},
          };
        }
      });

      // Convert the ordersMap back to an array to match the expected Order[] type
      let orders = Object.values(ordersMap);

      const ordersPromises = orders.map((datum: any) =>
        saveOrder(datum, { refetch: false })
      );

      await Promise.all(ordersPromises);
    }

    const promises = batchPreview.map((datum: any) => saveBatchPreview(datum));

    await Promise.all(promises);
    setUploading(false);

    getBatchPreview();
    getPatients();
    getOrders();
    navigate(ROUTES.BatchHistory.path);
  };

  const handleCheckboxChange = (e: any, id: string) => {
    setBatchPreview((prev: any[]) => {
      let data: any[] = [...prev];
      return data.map((d: any) => {
        if (d.rxNumber == id) {
          return {
            ...d,
            metadata: {
              ...d.metadata,
              [e.target.value]: e.target.checked,
            },
          };
        } else return d;
      });
    });
  };

  const handleValueChange = (e: any, id: string) => {
    setBatchPreview((prev: any[]) => {
      let data: any[] = [...prev];
      return data.map((d: any) => {
        if (d.rxNumber == id) {
          return {
            ...d,
            [e.target.name]: e.target.value,
          };
        } else return d;
      });
    });
  };

  const searchFilter = (data: any[], text: string) =>
    data.filter(
      (d) =>
        (d.firstName + " " + d.lastName)
          .toLowerCase()
          .includes(text.toLowerCase()) ||
        moment(d.dateOfBirth)
          .format("MM/DD/YYYY")
          .includes(text.toLowerCase()) ||
        d.species.toLowerCase().includes(text.toLowerCase()) ||
        d.med.toLowerCase().includes(text.toLowerCase()) ||
        d.dosage_form.toLowerCase().includes(text.toLowerCase())
    );

  let filteredBatch = searchFilter(batchPreview, searchText);

  const rows = filteredBatch.map((b: any) => (
    <Table.Tr key={b.id || b.rxNumber}>
      <Table.Td>{highlightedText(b.firstName, searchText)}</Table.Td>
      <Table.Td>{highlightedText(b.lastName, searchText)}</Table.Td>
      <Table.Td>{highlightedText(b.species, searchText)}</Table.Td>
      <Table.Td>
        {highlightedText(
          moment(b.dateOfBirth).format("MM/DD/YYYY"),
          searchText
        )}
      </Table.Td>
      <Table.Td>{b.phone}</Table.Td>
      <Table.Td>{b.doctor}</Table.Td>
      <Table.Td>{highlightedText(b.med, searchText)}</Table.Td>
      <Table.Td>{highlightedText(b.dosage_form, searchText)}</Table.Td>
      <Table.Td>{b.qty}</Table.Td>
      <Table.Td>{b.price}</Table.Td>
      <Table.Td>{b.memo}</Table.Td>
      <Table.Td>{b.authRefills}</Table.Td>
      <Table.Td>
        {/* <Checkbox
          value={BatchRuleActionType.AltQuantity}
          checked={b?.metadata && b.metadata[BatchRuleActionType.AltQuantity]}
          onChange={(e) => handleCheckboxChange(e, b.rxNumber)}
        /> */}
        <TextInput
          size="sm"
          radius="sm"
          value={b.alt_qty}
          name="alt_qty"
          onChange={(e) => handleValueChange(e, b.rxNumber)}
        />
      </Table.Td>
      <Table.Td>
        {/* <Checkbox
          value={BatchRuleActionType.AltPrice}
          checked={b?.metadata && b.metadata[BatchRuleActionType.AltPrice]}
          onChange={(e) => handleCheckboxChange(e, b.rxNumber)}
        /> */}
        <TextInput
          size="sm"
          radius="sm"
          value={b.alt_price}
          name="alt_price"
          onChange={(e) => handleValueChange(e, b.rxNumber)}
        />
      </Table.Td>
      <Table.Td>
        <Checkbox
          value={BatchRuleActionType.RequireSignature}
          checked={
            b?.metadata && b.metadata[BatchRuleActionType.RequireSignature]
          }
          onChange={(e) => handleCheckboxChange(e, b.rxNumber)}
        />
      </Table.Td>
      <Table.Td>
        <Checkbox
          value={BatchRuleActionType.AutoRefill}
          checked={b?.metadata && b.metadata[BatchRuleActionType.AutoRefill]}
          onChange={(e) => handleCheckboxChange(e, b.rxNumber)}
        />
      </Table.Td>
      <Table.Td>
        <Checkbox
          value={BatchRuleActionType.Overnight}
          checked={b?.metadata && b.metadata[BatchRuleActionType.Overnight]}
          onChange={(e) => handleCheckboxChange(e, b.rxNumber)}
        />
      </Table.Td>
      <Table.Td>
        <Checkbox
          value={BatchRuleActionType.MustUseUPS}
          checked={b?.metadata && b.metadata[BatchRuleActionType.MustUseUPS]}
          onChange={(e) => handleCheckboxChange(e, b.rxNumber)}
        />
      </Table.Td>
      <Table.Td>
        <Checkbox
          value={BatchRuleActionType.CheckForPregnantBreastfeeding}
          checked={
            b?.metadata &&
            b.metadata[BatchRuleActionType.CheckForPregnantBreastfeeding]
          }
          onChange={(e) => handleCheckboxChange(e, b.rxNumber)}
        />
      </Table.Td>

      {/* no payment */}

      <Table.Td>
        <Checkbox
          readOnly
          value={BatchRuleActionType.NoPayment}
          checked={b?.metadata && b.metadata[BatchRuleActionType.NoPayment]}
        />
      </Table.Td>

      {/* Human Flavour */}
      <Table.Td>
        <Checkbox
          readOnly
          value={BatchRuleActionType.HumanMedicineFlavour}
          checked={
            b?.metadata && b.metadata[BatchRuleActionType.HumanMedicineFlavour]
          }
        />
      </Table.Td>

      {/* dog flavour */}
      <Table.Td>
        <Checkbox
          readOnly
          value={BatchRuleActionType.DogMedicineFlavour}
          checked={
            b?.metadata && b.metadata[BatchRuleActionType.DogMedicineFlavour]
          }
        />
      </Table.Td>
      {/* dog treat flavour  */}
      <Table.Td>
        <Checkbox
          readOnly
          value={BatchRuleActionType.DogTreatFlavour}
          checked={
            b?.metadata && b.metadata[BatchRuleActionType.DogTreatFlavour]
          }
        />
      </Table.Td>

      {/* cat flavour  */}
      <Table.Td>
        <Checkbox
          readOnly
          value={BatchRuleActionType.CatMedicineFlavour}
          checked={
            b?.metadata && b.metadata[BatchRuleActionType.CatMedicineFlavour]
          }
        />
      </Table.Td>

      {/* cat treat flavour  */}
      <Table.Td>
        <Checkbox
          readOnly
          value={BatchRuleActionType.CatTreatFlavour}
          checked={
            b?.metadata && b.metadata[BatchRuleActionType.CatTreatFlavour]
          }
        />
      </Table.Td>

      {/* rodent / bird flavour  */}

      <Table.Td>
        <Checkbox
          readOnly
          value={BatchRuleActionType.RodentBirdMedicineFlavour}
          checked={
            b?.metadata &&
            b.metadata[BatchRuleActionType.RodentBirdMedicineFlavour]
          }
        />
      </Table.Td>
    </Table.Tr>
  ));

  return (
    <Modal
      opened={open}
      onClose={() => {
        navigate(ROUTES.BatchHistory.path);
      }}
      fullScreen
      transitionProps={{ transition: "slide-up" }}
      styles={{
        header: {
          backgroundColor: colors.bg,
        },
        content: {
          backgroundColor: colors.bg,
        },
      }}>
      <Flex direction={"column"} gap={15}>
        <Flex
            justify={"space-between"}
            mx={15}
            mb={30}
            px={30}
            align={"flex-end"}>
          <Flex align={"center"} justify={"space-between"} w={"100%"} gap={40}>
            <Image w={133} mt={20} h={128} src={assets.images.logo}/>
            <Flex direction={"column"} align={"center"} gap={15}>
              <Title>Upload Batch Preview</Title>
              <Input
                  w={450}
                  className="colored-placeholder"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  variant="filled"
                  size="xl"
                  radius={"xl"}
                  bg={colors.bg}
                  placeholder="Search"
                  styles={{
                    input: {
                      background: "white",
                      borderColor: "#6E23CF",
                      borderWidth: "3px",
                    },
                    wrapper: {
                      borderRadius: rem("44px"),
                    },
                  }}
              />
            </Flex>
            <div/>
          </Flex>
        </Flex>

        <Flex mah={"65vh"} style={{overflowY: "auto", overflowX: "auto"}}>
          <LoadingOverlay visible={loading}/>
          <Table
              highlightOnHover
              withTableBorder
              withColumnBorders
              styles={{
                th: {
                  height: 100,
                },
              }}>
            <Table.Thead
                bg={colors.bg}
                style={{position: "sticky", top: 0, zIndex: 999}}>
              <Table.Tr>
                <Table.Th>FIRST NAME</Table.Th>
                <Table.Th>LAST NAME</Table.Th>
                <Table.Th>SPECIES</Table.Th>
                <Table.Th>DOB</Table.Th>
                <Table.Th>CELL</Table.Th>
                <Table.Th>DR</Table.Th>
                <Table.Th>MED</Table.Th>
                <Table.Th>DOSAGE FORM</Table.Th>
                <Table.Th>QTY</Table.Th>
                <Table.Th>PRICE</Table.Th>
                <Table.Th>MEMO</Table.Th>
                <Table.Th>Refill</Table.Th>
                <Table.Th>ALTER QTY</Table.Th>
                <Table.Th>ALT PRICE</Table.Th>
                <RotatedTh>DELIVERY SIG</RotatedTh>
                <RotatedTh>AUTO REFILL</RotatedTh>
                <RotatedTh>OVER NIGHT</RotatedTh>
                <RotatedTh>MUST UPS</RotatedTh>
                <RotatedTh>PREG / BF</RotatedTh>
                <RotatedTh>No Payment</RotatedTh>
                <RotatedTh>Human Flavor</RotatedTh>
                <RotatedTh>Dog Flavor</RotatedTh>
                <RotatedTh>Dog Treat Flavor</RotatedTh>
                <RotatedTh>Cat Flavor</RotatedTh>
                <RotatedTh>Cat Treat Flavor</RotatedTh>
                <RotatedTh>Rodent Bird Flavor</RotatedTh>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody bg={"white"}>{rows}</Table.Tbody>
          </Table>
        </Flex>

        <div>Rows Count : {rows.length}</div>
        <Button
            loading={uploading}
            onClick={handleUpload}
            style={{alignSelf: "center"}}
            radius={"xl"}
            size="xl">
          {update ? "Update " : "Upload "}
          Batch
        </Button>
      </Flex>
    </Modal>
  );
}

export default BatchPreview;

const RotatedTh = styled.th`
  transform: rotate(-45deg);
  font-size: 12px;  
`;
