/** @format */

import { Button, Flex } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect, useState } from "react";
import { openModal } from "../../../modules";
import { useAppData } from "../../../contexts/AppContext";
import Rule from "./Rule";
import { BatchRule, LogicalOperators } from "../../../constants";
import { closeModal } from "@mantine/modals";
import AddActionForm from "./AddActionForm";

function AddBatchRule({
  onSubmit,
  editRule = {} as BatchRule,
}: {
  onSubmit: () => void;
  editRule?: BatchRule;
}) {
  const {
    batchRulesData: { saveBatchRule },
  } = useAppData();

  const [savingRule, setSavingRule] = useState(false);

  const batchRule = useForm<any>({
    initialValues: {
      condition: editRule?.conditionKey || "medication-contains",
      conditionValue: editRule?.conditionValue || [""],
      action: editRule?.action?.title || "auto-refill",

      secondCondition: {
        key: editRule?.secondCondition?.key || "medication-contains",
        value: editRule?.secondCondition?.value || "",
        logicalOp: editRule?.secondCondition?.logicalOp || LogicalOperators.AND,
      },
    },
    validate: {
      condition: (value) => (value === "" ? "Condition required" : null),
      conditionValue: (value) =>
        value === "" ? "Condition Value required" : null,
      action: (value) => (value === "" ? "Action required" : null),
    },
  });

  const handleSaveRule = async (values: any) => {
    setSavingRule(true);
    await saveBatchRule({
      action: {
        title: values.action,
      },
      conditionKey: values.condition,
      conditionType: values.condition,
      conditionValue: values.conditionValue,

      secondCondition: values.secondCondition || {},

      isActive: true,

      id: editRule?.id,
    });
    setSavingRule(false);
    onSubmit();
  };

  const onAddAction = () => {
    openModal({
      id: "add-action",
      bg: "white",
      children: (
        <AddActionForm
          onSubmit={() => {
            closeModal("close-modal");
          }}
        />
      ),
    });
  };

  useEffect(() => {
    if (batchRule) {
      batchRule.setInitialValues({
        condition: editRule?.conditionKey || "medication-contains",
        conditionValue: editRule?.conditionValue || [""],
        action: editRule?.action?.title || "auto-refill",

        secondCondition: {
          key: editRule?.secondCondition?.key || "medication-contains",
          value: editRule?.secondCondition?.value || "",
          logicalOp:
            editRule?.secondCondition?.logicalOp || LogicalOperators.AND,
        },
      });
    }
  }, [batchRule, editRule]);

  return (
    <form onSubmit={batchRule.onSubmit((values) => handleSaveRule(values))}>
      <Flex direction={"column"}>
        <Flex p={20} justify={"space-between"}>
          <Rule
            rule={{
              action: batchRule.values.action,
              condition: batchRule.values.condition,
              conditionValue: batchRule.values.conditionValue,
              secondCondition: batchRule?.values?.secondCondition,
            }}
            form={batchRule}
            editable={true}
          />
        </Flex>
        <Button
          onClick={onAddAction}
          variant="transparent"
          style={{ alignSelf: "flex-end", textDecoration: "underline" }}
          size="compact-lg"
        >
          Add Action
        </Button>
        <Button
          type="submit"
          style={{ alignSelf: "center" }}
          size="lg"
          radius={"xl"}
          loading={savingRule}
        >
          Save Batch Rule
        </Button>
      </Flex>
    </form>
  );
}

export default AddBatchRule;
