import { Card, Flex } from "@mantine/core";
import React from "react";
import styled from "styled-components";

function PageWrapper({
  children,
  footer,
}: {
  children: React.ReactNode;
  footer?: React.ReactNode;
}) {
  return (
    <PageContainer>
      <Card p={30} radius={"lg"} h={"78vh"}>
        {children}
      </Card>
      <Flex align={"center"}>{footer}</Flex>
    </PageContainer>
  );
}

export default PageWrapper;

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
`;
