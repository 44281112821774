/** @format */

import { useEffect, useState, useCallback } from "react";
import { MessageThread, User } from "../constants";
import {
  getAllDocs,
  upsertDoc,
  getDocsByQuery,
  upsertChatThread,
  sendMessage,
  fetchMessages,
  fetchAllUsersWithChatThreads,
  fetchChatThreadsForUser,
  // replyToMessageIfExists,
  subscribeToUserInfo,
  subscribeToThreadUpdates,
} from "../repositories/firebase";
import { COLLECTIONS } from "../constants/global";
import { notifications } from "@mantine/notifications";
import { Order } from "../constants";

interface SendMessageResponse {
  threadId: string;
  mostRecentThread: any;
}
export interface UseMessages {
  loading: boolean;
  updateUser: (
    data: any,
    medication: any,
    id: any,
    orderId: any
  ) => Promise<void>;
  getRecentorder: (id: any) => Promise<object>;
  getMessages: (lastMessage: string) => Promise<void>;
  chatUsers: any[]; // Define the type of `chatUsers` if possible, e.g., `User[]` if you have a `User` type defined
  fetchChatUsers: () => Promise<any[]>; // As specified, returns a promise that resolves to an array
  cleanupListeners: () => void; // Assuming there's a method to cleanup listeners
  // fetchThreadMessages: (id: any) => Promise<any[]>;
  fetchThreadMessages: (id: any) => void; // Changed return type as it no longer returns directly
  addThreadListener: (threadId: any, userId: any) => void;
  updateUsers: (updatedUsers: any) => void;

  sendThreadMessage: (
    threadId: any,
    senderId: any,
    receiverId: any,
    messageId: any,
    message: any
  ) => Promise<SendMessageResponse>;
  messages: MessageThread[];
}

export const useMessages = (): UseMessages => {
  // const [messages, setMessages] = useState<MessageThread[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [messages, setMessages] = useState<any[]>([]);
  const [chatUsers, setChatUsers] = useState<any[]>([]);
  let unsubscribeFns = [];
  const getMessages = async (user: string) => {
    // await replyToMessageIfExists('(215) 215-2155');
    try {
      const _messages = await getAllDocs<MessageThread>(
        COLLECTIONS.MESSAGE_THREAD,
        user
      );
      setMessages(_messages);
    } catch (error) {
      console.log("Error getting messages!", error);
    }
  };

  const getRecentorder = async (id: any) => {
    try {
      if (id === undefined) {
        return [];
      }

      const _orders = await getDocsByQuery<Order>(
        COLLECTIONS.ORDERS,
        "userId",
        id
      );
      return _orders;
    } catch (error) {
      console.log("Error getting orders!", error);
      return [];
    }
  };

  const updateUser = async (
    dataTemp: any,
    medications: any,
    id: any,
    orderId: any
  ) => {
    try {
      //remove order and mostRecentThread  key from dataTemp
      delete dataTemp.order;
      delete dataTemp.mostRecentThread;
      delete dataTemp.id;
      const { success, code, message, data } = await upsertDoc<User>(
        COLLECTIONS.USERS,
        dataTemp,
        id
      );
      const medicationTemp = {
        medications: medications,
      };
      if (orderId !== undefined) {
        await upsertDoc(COLLECTIONS.ORDERS, medicationTemp, orderId);
      }

      if (success) {
        notifications.show({
          title: "Patient updated successfully",
          message: "",
          variant: "success",
        });
        // console.log("Success!", message);
        // return { id: data?.id, data: data?.phone};
      } else {
        notifications.show({
          title: message,
          message: "",
          variant: "error",
        });
        console.log("Error saving Patient!", message, code);
      }
    } catch (error) {
      console.error("Error!", error);
    }
  };

  // const fetchChatUsers = async () => {
  //   try {
  //     const results = await fetchAllUsersWithChatThreads();
  //     return results;
  //   } catch (error) {
  //     console.error("Error!", error);
  //   }
  // };

  const fetchChatUsers = useCallback(async () => {
    const users = await fetchAllUsersWithChatThreads();
    setChatUsers(users);

    // Clean up any existing listeners before setting up new ones
    unsubscribeFns.forEach((unsub) => unsub());
    unsubscribeFns = [];

    // Set up real-time listeners for each user's document and most recent thread
    users.forEach((user) => {
      const userUnsub = subscribeToUserInfo(user.id, (updatedUser) => {
        setChatUsers((currentUsers) =>
          currentUsers.map((u) =>
            u.id === user.id ? { ...u, ...updatedUser } : u
          )
        );
      });
      unsubscribeFns.push(userUnsub);

      if (user.mostRecentThread) {
        const threadUnsub = subscribeToThreadUpdates(
          user.mostRecentThread.id,
          (updatedThread) => {
            setChatUsers((currentUsers) =>
              currentUsers.map((u) =>
                u.id === user.id ? { ...u, mostRecentThread: updatedThread } : u
              )
            );
          }
        );
        unsubscribeFns.push(threadUnsub);
      }
    });

    return users;
  }, []);

  const addThreadListener = (threadId, userId) => {
    const threadUnsub = subscribeToThreadUpdates(threadId, (updatedThread) => {
      console.log("Thread updated!", updatedThread);
      setChatUsers((currentUsers) =>
        currentUsers.map((u) =>
          u.id === userId ? { ...u, mostRecentThread: updatedThread } : u
        )
      );
    });
    unsubscribeFns.push(threadUnsub);
    console.log("users", chatUsers);
  };

  const updateUsers = (updatedUser) => {
    //handle subscribe to thread
    // setChatUsers((currentUsers) =>
    //   currentUsers.map((u) => (u.id === updatedUser.id ? updatedUser : u))
    // );

    const threadUnsub = subscribeToThreadUpdates(
      updatedUser.mostRecentThread.id,
      (updatedThread) => {
        setChatUsers((currentUsers) =>
          currentUsers.map((u) =>
            u.id === updatedUser.id
              ? { ...u, mostRecentThread: updatedThread }
              : u
          )
        );
      }
    );
    unsubscribeFns.push(threadUnsub);
  };

  const cleanupListeners = useCallback(() => {
    unsubscribeFns.forEach((unsub) => unsub());
    unsubscribeFns = [];
  }, []);

  // const fetchThreadMessages2 = async (threadId: any) => {
  //   try {
  //     if (threadId === undefined || threadId === null) {
  //       return [];
  //     }
  //     const results = await fetchMessages(threadId);
  //     return results;
  //   } catch (error) {
  //     console.error("Error!", error);
  //   }
  // };

  // const fetchThreadMessages22 = useCallback((threadId: any) => {
  //   if (threadId === undefined || threadId === null) {
  //     return;
  //   }
  //   // Setup the real-time listener
  //   return fetchMessages(threadId, (newMessages) => {
  //     setMessages(newMessages);
  //   });
  // }, []);

  const fetchThreadMessages = useCallback((threadId: any) => {
    if (threadId === undefined || threadId === null) {
      setMessages([]);
      return () => {}; // Return a no-op if no thread ID
    }

    // Return the unsubscribe function from the subscription
    return fetchMessages(threadId, (newMessages) => {
      setMessages(newMessages);
    });
  }, []);

  const sendThreadMessage = async (
    threadId: any,
    senderId: any,
    receiverId: any,
    messageId: any,
    message: any
  ) => {
    try {
      if (threadId === undefined) {
        threadId = await upsertChatThread(senderId, receiverId, message);
      }
      const res = await sendMessage(
        threadId,
        senderId,
        receiverId,
        messageId,
        message
      );
      const response = {
        threadId: threadId,
        mostRecentThread: res,
      };
      return response;
    } catch (error) {
      console.error("Error!", error);
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      // await getMessages("test");
      setLoading(false);
    })();
  }, []);

  return {
    loading,
    getMessages,
    updateUser,
    messages,
    getRecentorder,
    fetchChatUsers,
    fetchThreadMessages,
    sendThreadMessage,
    chatUsers,
    cleanupListeners,
    addThreadListener,
    updateUsers,
  };
};
