import {
  ActionIcon,
  Button,
  Card,
  Checkbox,
  Flex,
  Image,
  LoadingOverlay,
  Space,
  Text,
  Tooltip,
} from "@mantine/core";
import styled from "styled-components";
import { IAdminLog, User, assets, colors } from "../../constants";
import moment from "moment";
import AdminHistory from "../AdminAccount/components/AdminHistory";
import { closeModal } from "@mantine/modals";
import { AbsolutelyCentered, PageHeader } from "../../components";
import { PageWrapper } from "../../layout";
import { IconPencil, IconCopy, IconCheck } from "@tabler/icons-react";
import { Fragment, useEffect, useState } from "react";
import EditPatientForm from "./components/EditPatientForm";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useAppData } from "../../contexts/AppContext";
import { openModal } from "../../modules";
import RefundPatientForm from "./components/RefundPatientForm";
import EditInternalNote from "./components/EditInternalNote";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";

const SHIPPING_OPTIONS = [
  { label: "Overnight UPS $33", value: "33" },
  { label: "USPS $9", value: "9" },
  { label: "UPS Ground $14", value: "14" },
  { label: "Pick Up", value: "pick-up" },
  { label: "Free USPS", value: "0" },
];

function PatientDetails() {
  const navigate = useNavigate();
  const [patient, setPatient] = useState<User | any>();
  const [logsLoading, setLogsLoading] = useState<boolean>(false);
  const [copiedEmail, setCopiedEmail] = useState(false);
  const [copiedAddress, setCopiedAddress] = useState(false);
  const [copiedBillingAddress, setCopiedBillingAddress] = useState(false);
  const params = useParams();
  const {
    patientsData: { patients, loading },
    ordersData: { orders },
  } = useAppData();

  let order = orders?.find((o) => o.userId === patient?.id);

  useEffect(() => {
    if (params?.patientId) {
      let _patient = patients.find((p) => p.id === params?.patientId);
      if (_patient) {
        setPatient({ ..._patient });
      } else {
        setPatient(undefined);
      }
    }
  }, [params, patients]);

  const onHistoryClick = async (p: any) => {
    let id = p?.id;
    const firstNestedCollectionRef = collection(db, "users", id, "user-logs");

    setLogsLoading(true);
    const firstNestedQuerySnapshot = await getDocs(firstNestedCollectionRef);
    setLogsLoading(false);

    let userLogs = firstNestedQuerySnapshot.docs.map((firstNestedDoc) => ({
      id: firstNestedDoc.id,
      ...(firstNestedDoc.data() as any),
    }));

    openModal({
      id: "admin-history",
      children: <AdminHistory logs={userLogs} />,
    });
  };

  const onEditPatient = (patient: any) => {
    openModal({
      id: "edit-patient",
      children: (
        <EditPatientForm
          initialValues={patient}
          onSubmit={() => {
            closeModal("edit-patient");
          }}
        />
      ),
      size: "calc(70vw)",
    });
  };

  const onRefund = (order: any) => {
    openModal({
      id: "refund-patient",
      children: (
        <RefundPatientForm
          type="refund"
          order={order}
          patient={patient}
          onSubmit={() => {
            closeModal("refund-patient");
          }}
        />
      ),
      size: "calc(50vw)",
    });
  };

  const onChargePatient = (order: any) => {
    openModal({
      id: "charge-patient",
      children: (
        <RefundPatientForm
          type="charge"
          order={order}
          patient={patient}
          onSubmit={() => {
            closeModal("charge-patient");
          }}
        />
      ),
      size: "calc(50vw)",
    });
  };

  const editInternalNotes = (patient: User) => {
    openModal({
      id: "edit-internal-notes",
      children: (
        <EditInternalNote
          patient={patient}
          onSubmit={() => {
            closeModal("edit-internal-notes");
          }}
        />
      ),
      size: "calc(50vw)",
    });
  };

  const copyToClipboard = (
    text: string,
    setCopied: (value: boolean) => void
  ) => {
    navigator.clipboard.writeText(text);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  return (
    <PageWrapper
      footer={
        <Flex
          style={{ cursor: "pointer", marginTop: "10px" }}
          onClick={() => {
            navigate(ROUTES.PatientLookUp.path);
          }}
          align={"center"}
          gap={15}
        >
          <Image radius={"xl"} src={assets.icons.leftToggle} />
          <Back>Back to Patient Portal </Back>
        </Flex>
      }
    >
      <PageHeader
        searchProps={{
          placeholder: "Search By Name or Date of Birth",
        }}
        buttons={
          <Fragment>
            <Button
              radius={"xl"}
              size="xl"
              onClick={() => onEditPatient(patient)}
            >
              <span style={{ paddingRight: 5 }}>Edit Patient Details</span>
              <IconPencil />
            </Button>
          </Fragment>
        }
      />

      {loading ? (
        <LoadingOverlay visible />
      ) : patient ? (
        <Flex
          direction={"column"}
          gap={15}
          p={20}
          style={{ overflowY: "scroll" }}
        >
          <Flex gap={10} align={"center"}>
            <Title>
              {patient?.firstName} {patient?.lastName}
            </Title>
            <Image src={assets.icons.verifiedProfile} w={24} h={24} />
          </Flex>
          <Flex>
            <Flex
              direction={"column"}
              flex={1}
              gap={25}
              justify={"space-between"}
            >
              <Flex direction={"column"} gap={10}>
                <Label>Date of Birth</Label>
                <Value>
                  {moment(patient?.dateOfBirth).format("MM/DD/YYYY")}
                </Value>
              </Flex>

              <Flex direction={"column"} gap={10}>
                <Label>Email</Label>
                <Value>
                  {patient?.email}
                  <Tooltip label="Copy to clipboard">
                    <ActionIcon
                      onClick={() =>
                        copyToClipboard(patient?.email, setCopiedEmail)
                      }
                      variant="transparent"
                    >
                      {copiedEmail ? (
                        <IconCheck size={16} />
                      ) : (
                        <IconCopy size={16} />
                      )}
                    </ActionIcon>
                  </Tooltip>
                </Value>
              </Flex>
              <Space h={"sm"} />
              <Flex direction={"column"} gap={10}>
                <Label>Phone Number</Label>
                <Value>{patient?.phone}</Value>
              </Flex>

              <Space h={"sm"} />
              <Flex direction={"column"} gap={10}>
                <Label>Credit Card</Label>
                <Value>
                  Last 4 digits: ***
                  {order?.paymentMethod?.cardNumber.slice(
                    order?.paymentMethod?.cardNumber?.length - 4
                  )}{" "}
                </Value>
                <Value>
                  Expiration: {order?.paymentMethod?.expirationMonth}/
                  {order?.paymentMethod?.expirationYear}
                </Value>
              </Flex>

              <Flex direction={"column"} gap={10} align={"center"}>
                <Button
                  onClick={() => onChargePatient(order)}
                  size="lg"
                  radius={"xl"}
                >
                  Charge Patient
                </Button>
                <Button
                  variant="transparent"
                  td={"underline"}
                  size="lg"
                  radius={"xl"}
                  onClick={() => onRefund(order)}
                >
                  Refund Patient
                </Button>
              </Flex>
            </Flex>
            <Flex
              direction={"column"}
              justify={"space-between"}
              flex={1}
              gap={10}
            >
              <Flex direction={"column"} gap={10}>
                <Label>Shipping Address</Label>
                <Value>
                  {`${
                    patient?.address?.street
                      ? patient.address.street + ", "
                      : ""
                  }${
                    patient?.address?.city ? patient.address.city + ", " : ""
                  }${patient?.address?.state ? patient.address.state : ""}`}
                  <Tooltip label="Copy to clipboard">
                    <ActionIcon
                      onClick={() =>
                        copyToClipboard(
                          `${
                            patient?.address?.street
                              ? patient.address.street + ", "
                              : ""
                          }${
                            patient?.address?.city
                              ? patient.address.city + ", "
                              : ""
                          }${
                            patient?.address?.state ? patient.address.state : ""
                          }`,
                          setCopiedAddress
                        )
                      }
                      variant="transparent"
                    >
                      {copiedAddress ? (
                        <IconCheck size={16} />
                      ) : (
                        <IconCopy size={16} />
                      )}
                    </ActionIcon>
                  </Tooltip>
                </Value>
              </Flex>
              <Flex direction={"column"} gap={10}>
                <Label>Billing Address</Label>
                <Value>
                  {patient?.sameAddress
                    ? "Same as Shipping Address"
                    : `${
                        patient?.billingAddress?.street
                          ? patient.billingAddress.street + ", "
                          : ""
                      }${
                        patient?.billingAddress?.city
                          ? patient.billingAddress.city + ", "
                          : ""
                      }${
                        patient?.billingAddress?.state
                          ? patient.billingAddress.state
                          : ""
                      }`}
                  <Tooltip label="Copy to clipboard">
                    <ActionIcon
                      onClick={() =>
                        copyToClipboard(
                          `${
                            patient?.sameAddress
                              ? "Same as Shipping Address"
                              : `${
                                  patient?.billingAddress?.street
                                    ? patient.billingAddress.street + ", "
                                    : ""
                                }${
                                  patient?.billingAddress?.city
                                    ? patient.billingAddress.city + ", "
                                    : ""
                                }${
                                  patient?.billingAddress?.state
                                    ? patient.billingAddress.state
                                    : ""
                                }`
                          }`,
                          setCopiedBillingAddress
                        )
                      }
                      variant="transparent"
                    >
                      {copiedBillingAddress ? (
                        <IconCheck size={16} />
                      ) : (
                        <IconCopy size={16} />
                      )}
                    </ActionIcon>
                  </Tooltip>
                </Value>
              </Flex>

              <Flex direction={"column"} mt={"sm"}>
                <Text fw={700}>Questions</Text>
                {order?.questions?.length
                  ? order?.questions.map((q) => {
                      return (
                        <Flex direction={"column"} key={q.question}>
                          <Text fw={500}>{q.question}</Text>
                          <Text size="sm">
                            {q.none || !q.answer ? <em>None</em> : q?.answer}
                          </Text>
                        </Flex>
                      );
                    })
                  : null}
                <Text fw={500}>Are you Pregnant / Breastfeeding?</Text>
                {order?.pregBF ? <Text>Yes</Text> : <em>None</em>}
              </Flex>
              <Button
                loading={logsLoading}
                variant="transparent"
                td={"underline"}
                size="lg"
                radius={"xl"}
                onClick={() => onHistoryClick(patient)}
              >
                View Account History
              </Button>
            </Flex>
            <Flex direction={"column"} flex={2} gap={20}>
              <Card radius={"lg"} bg={colors.bg} h={"50%"}>
                <Flex
                  direction={"column"}
                  gap={25}
                  style={{ overflowY: "auto" }}
                  px={5}
                >
                  <Flex justify={"space-between"} align={"center"}>
                    <MedicationTitle>Active Medications</MedicationTitle>
                    <Flex gap={10} align={"center"}>
                      <Label>Ordered Date</Label>
                      <Text>
                        {order?.orderDate
                          ? moment(order.orderDate.toDate()).format(
                              "MMMM DD YYYY "
                            )
                          : "N/A"}
                      </Text>
                    </Flex>
                  </Flex>
                  {order?.shippingOption ? (
                    <Text fw="500">
                      Delivery:{" "}
                      <em>
                        {SHIPPING_OPTIONS.find(
                          (opt) => opt.value === order?.shippingOption
                        )?.label || ""}
                      </em>
                    </Text>
                  ) : null}
                  {order?.medications?.map((med) => (
                    <Flex
                      justify={"space-between"}
                      px={5}
                      gap={40}
                      key={med.itemName}
                    >
                      <MedicationTitle>{med.itemName}</MedicationTitle>
                      <MedicationTitle>{med.dosageForm}</MedicationTitle>
                      <MedicationTitle>
                        Quantity: {med.quantity}
                      </MedicationTitle>
                      <MedicationTitle>{med.price}</MedicationTitle>
                      <Flex direction={"column"} gap={"sm"}>
                        <NonClickableCheckbox
                          readOnly
                          label={"Auto Refill"}
                          checked={med?.autoRefill}
                        />
                      </Flex>
                    </Flex>
                  ))}
                  <Text fw="500">
                    Total Amount{" "}
                    {order.totalAmount !== null &&
                    order.totalAmount !== undefined
                      ? new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(order.totalAmount)
                      : "$0.00"}
                  </Text>
                </Flex>
              </Card>
              <Flex direction={"column"} gap={5} h={"40%"}>
                <Flex gap={10} align={"center"}>
                  <Title>Internal Notes</Title>
                  <ActionIcon
                    onClick={() => editInternalNotes(patient)}
                    variant="transparent"
                  >
                    <Image src={assets.icons.edit} w={20} h={20} />
                  </ActionIcon>
                </Flex>
                <Card radius={"lg"} bg={colors.bg} h={"100%"}>
                  {patient?.internalNotes}
                </Card>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <AbsolutelyCentered>Patient not found</AbsolutelyCentered>
      )}
    </PageWrapper>
  );
}

export default PatientDetails;

const Title = styled.div`
  //styleName: Paragraph 1 - Bold;
  font-family: DM Sans;
  font-size: 22px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
`;

const Label = styled.div`
  //styleName: Paragraph 2 - Bold;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;
const Value = styled.div`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;

const MedicationTitle = styled.div`
  font-family: Manrope;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;

const Back = styled.div`
  font-family: Manrope;
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;

const NonClickableCheckbox = styled(Checkbox)`
  pointer-events: none;
`;
