/** @format */

import { Card } from "@mantine/core";
import styled from "styled-components";
import { useAuth } from "../../../hooks/useAuth";
import { usePatients } from "../../../hooks/usePatients";
import { useMessages } from "../../../hooks/useMessages";
import { useAppData } from "../../../contexts/AppContext";
import moment from "moment";

import React, { useState } from "react";
function ChatBubble({
	type,
	text,
	timestamp,
}: {
	type: "sent" | "received";
	text: string;
	timestamp: any;
}) {
	const formatDate = (timestamp) => {
		//if timestamp is null or empty assign it current date value
		if (!timestamp) {
		  timestamp = new Date();
		}

		if (timestamp instanceof Date) {
		  return moment(timestamp).format("MMMM D, h:mm A");
		}
		// console.log("timestamp", timestamp);
		const date = timestamp.toDate();
		return moment(date).format("MMMM D, h:mm A");
	  };
		
	const { loading, messages, getMessages } = useMessages();

	
	return (
		<Card
			maw={"70%"}
			radius={"lg"}
			bg={type === "received" ? "#F8F3F0" : "#F1E6FF"}
			style={{
				alignSelf: type === "received" ? "flex-start" : "flex-end",
			}}>
			<Text>{text}</Text>
			<small style={{ display: 'block', textAlign: 'right', color: '#888', fontSize: '0.75rem', marginTop: '4px' }}>
        {formatDate(timestamp)}
    </small>

		</Card>
	);
}

export default ChatBubble;

const Text = styled.div`
	font-family: Manrope;
	font-size: 16px;
	font-weight: 400;
	line-height: 21px;
	letter-spacing: 0em;
	text-align: left;
`;
