import { ActionIcon, Card, Flex, Image, Space, Text } from "@mantine/core";
import { Order, assets, colors } from "../../../constants";
import { openModal } from "../../../modules";
import { closeModal } from "@mantine/modals";
import EditPatientChatForm from "./EditPatientChatForm";
import { useMessages } from "../../../hooks/useMessages";
import React, { useState, useEffect } from "react";
import moment from "moment";

interface Props {
  userInfo: any;
  onPatientUpdate: (updatedData: any) => void;
}
function PatientProfile({ userInfo, onPatientUpdate }: Props) {
  const { getRecentorder } = useMessages();
  const [order, setOrder] = useState<Order>(null);
  const [patient, setPatient] = useState(userInfo);

  const onEditPatient = () => {
    userInfo.order = order;

    openModal({
      id: "edit-patient",
      children: (
        <EditPatientChatForm
          onSubmit={() => {
            closeModal("edit-patient");
          }}
          initialValues={patient}
          onPatientUpdate={handlePatientUpdate}
        />
      ),
      size: "calc(70vw)",
    });
  };
  const handlePatientOrder = async (id: any) => {
    getRecentorder(id)
      .then((data: any) => {
        setOrder(data[0]);
      })
      .catch((error: any) => {
        console.error("Failed to update user", error);
      });
  };

  const handlePatientUpdate = (updatedData) => {
    setPatient(updatedData);
    onPatientUpdate(updatedData);
  };
  const formatDate = (timestamp) => {
    let messageTime = null;
    if (timestamp instanceof Date) {
      // return moment(timestamp).format("MM/DD/yy h:mm A");
      messageTime = timestamp;
    } else {
      messageTime = timestamp.toDate();
    }
    const now = new Date();
    const messageTimeMillis = messageTime.getTime();
    const nowMillis = now.getTime();
    const timeDiff = nowMillis - messageTimeMillis; // Difference in millisec

    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} day${days > 1 ? "s" : ""} ago`;
    if (hours > 0) return `${hours} h ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    return `${seconds} second${seconds > 1 ? "s" : ""} ago`;

    // return moment(date).format("MM/DD/yy h:mm A");
  };

  const formatStatus = (status: any) => {
    if (!status) return "";
    // Split the status by hyphens or underscores
    const words = status.replace(/[-_]/g, " ").split(" ");
    // Capitalize the first letter of each word
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    // Join the words with spaces
    return capitalizedWords.join(" ");
  };

  useEffect(() => {
    const userId = userInfo?.id;
    handlePatientOrder(userId);
    setPatient(userInfo);
  }, [userInfo?.id, userInfo?.mostRecentThread]); // Dependency array

  


  return (
    <Flex direction="column" justify={"space-between"}>
      <Flex justify={"space-between"}>
        <Text fw={700} size={"20px"}>
          Patient Profile
        </Text>
        <ActionIcon variant="transparent">
          <Image
            w={20}
            src={assets.icons.edit}
            onClick={() => onEditPatient()}
          />
        </ActionIcon>
      </Flex>
      <Space h="lg" />
      <Flex direction="column">
        <Text fw={600} size={"16px"}>
          {patient?.firstName} {patient?.lastName}
        </Text>
        <Space h="xs" />
        <Text size="sm">
          Last Message:&nbsp;
          {patient?.mostRecentThread?.lastMessageTimestamp
            ? formatDate(patient?.mostRecentThread?.lastMessageTimestamp)
            : ""}
        </Text>
      </Flex>

      <Space h="lg" />
      <Flex direction="column">
        <Text size="sm">
          <strong>Order Status:</strong>
        </Text>
        <Space h="xs" />
        <Text size="sm">{formatStatus(order?.currentStatus?.status)}</Text>
      </Flex>

      <Space h="lg" />
      <Flex direction="column">
        <Text fw={600} size={"16px"}>
          Contact Info
        </Text>
        <Space h="xs" />
        <Text size="sm">
          <strong>Email:</strong>
          {patient?.email}
        </Text>
        <Space h="xs" />
        <Text size="sm">
          <strong>Phone Number: </strong> {patient?.phone}
        </Text>
      </Flex>

      <Space h="lg" />
      <Flex direction="column">
        <Text fw={500}>
          <strong>Address: </strong>
        </Text>
        <Space h="xs" />
        <Text size="sm">
          {patient?.address?.street}
          <br></br>
          {patient?.address?.street2}
          {patient?.address?.street2 ? <br></br> : null}
          {patient?.address?.city}, {patient?.address?.state}
          <br></br>
          {patient?.address?.postalCode} <br></br>
          United States
          {/* 346 Convenience Lane Houston, Texas 77001 United States */}
        </Text>
      </Flex>

      <Space h="lg" />
      <Flex direction="column">
        <Text fw={700}>Active Medications</Text>
        {order?.medications.map((row, index) => (
          <React.Fragment key={index}>
            <Space h="xs" />
            <Flex justify={"space-between"}>
              <Text size="sm">
                {row.itemName}
                {/* {row.dosageForm} */}
              </Text>
              <Text> {+row.quantity}mg</Text>
            </Flex>
          </React.Fragment>
        ))}

      

        <Space h="md" />

        <Flex justify={"space-between"}>
          <Text fw={700}>Internal Notes</Text>
          <ActionIcon variant="transparent">
            {/* <Image w={20} src={assets.icons.edit} /> */}
          </ActionIcon>
        </Flex>
        <Space h="md" />
        <Card radius={"lg"} bg={colors.bg} h={"100%"}>
          {patient?.internalNotes}
          {/* "This is an example of an internal note. Lorem ipsum dolor sit amat.", */}
        </Card>
        <Space h="md" />
      </Flex>
    </Flex>
  );
}

export default PatientProfile;
