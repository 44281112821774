/** @format */

import {
  ActionIcon,
  Button,
  Divider,
  Flex,
  Image,
  LoadingOverlay,
  Modal,
} from "@mantine/core";
import { useEffect, useState } from "react";
import colors from "../../constants/theme";
import styled from "styled-components";
import { IconPencil, IconX } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { BatchRule, assets } from "../../constants";
import { openConfirmationModal, openModal } from "../../modules";
import AddBatchRule from "./components/AddBatchRule";
import { useAppData } from "../../contexts/AppContext";
import { closeModal, modals } from "@mantine/modals";
import Rule from "./components/Rule";
import { ConfirmModalFrame } from "../../components";
import { COLLECTIONS } from "../../constants/global";
import { deleteDocFromCol } from "../../repositories/firebase";

function BatchRules() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [deleting, setDeleting] = useState<string | undefined>(undefined);

  const {
    batchRulesData: { batchRules, loading, getBatchRules },
  } = useAppData();

  useEffect(() => {
    setOpen(true);
  }, []);

  const onAddEditRule = (rule?: BatchRule) => {
    openModal({
      id: "add-batch-rule",
      size: "calc(80vw)",
      children: (
        <AddBatchRule
          onSubmit={() => {
            modals.close("add-batch-rule");
          }}
          editRule={rule}
        />
      ),
    });
  };

  const onRemoveRule = (rule?: BatchRule) => {
    openConfirmationModal({
      id: "confirm-delete-modal",
      children: (
        <ConfirmModalFrame
          onConfirm={async () => {
            if (rule?.id) {
              closeModal("confirm-delete-modal");
              setDeleting(rule?.id);
              await deleteDocFromCol(COLLECTIONS.BATCH_RULES, rule.id);
              await getBatchRules();
              setDeleting(undefined);
            }
          }}
          onCancel={() => {
            closeModal("confirm-delete-modal");
          }}
          title={`Are you sure you want to delete this rule?`}
        />
      ),
    });
  };

  return (
    <Modal
      opened={open}
      onClose={() => {
        navigate(ROUTES.BatchHistory.path);
      }}
      fullScreen
      transitionProps={{ transition: "slide-up" }}
      styles={{
        header: {
          backgroundColor: colors.bg,
        },
        content: {
          backgroundColor: colors.bg,
        },
      }}>
      <LoadingOverlay visible={loading} />
      <Flex
        justify={"space-between"}
        mx={15}
        mb={30}
        px={30}
        align={"flex-end"}>
        <Flex align={"center"} gap={40}>
          <Image
            style={{
              alignSelf: "center",
            }}
            w={133}
            mt={20}
            h={128}
            src={assets.images.logo}
          />
          <Title>Batch Rules</Title>
        </Flex>
        <Button onClick={() => onAddEditRule()} size="lg" radius={"xl"}>
          Add Batch Rules
        </Button>
      </Flex>
      <Flex p={20} direction={"column"}>
        {batchRules.map((rule) => {
          return (
            <Flex key={rule.id} p={20} justify={"space-between"}>
              <Rule
                rule={{
                  action: rule.action.title,
                  condition: rule.conditionKey,
                  conditionValue: rule.conditionValue,
                  secondCondition: rule?.secondCondition,
                }}
              />
              <Flex>
                <ActionIcon
                  onClick={() => onAddEditRule(rule)}
                  variant="subtle"
                  size={"lg"}>
                  <IconPencil size={"lg"} />
                </ActionIcon>
                <ActionIcon
                  loading={deleting === rule.id}
                  onClick={() => onRemoveRule(rule)}
                  variant="subtle"
                  size={"lg"}>
                  <IconX size={"lg"} />
                </ActionIcon>
              </Flex>
            </Flex>
          );
        })}

        <Divider size="md" color={"black"} />
      </Flex>
    </Modal>
  );
}

export default BatchRules;

const Title = styled.div`
  //styleName: Mobile Header 1;
  font-family: Manrope;
  font-size: 30px;
  font-weight: 800;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: left;
`;
