/** @format */

import { Routes, Route, Navigate } from "react-router-dom";
import PublicRoutes from "./PublicRoutes";
import ProtectedRoutes from "./ProtectedRoutes";
import { ROUTES } from "../constants/routes";
import {
  Archive,
  BatchHistory,
  Login,
  Messaging,
  OrderStatus,
  PatientLookUp,
} from "../pages";
import { AdminAccount } from "../pages/AdminAccount";
import { BatchRules } from "../pages/BatchRules";
import { BatchPreview } from "../pages/BatchPreview";
import { useAppData } from "../contexts/AppContext";
import PatientDetails from "../pages/PatientDetails/PatientDetails";
import { MessageTemplates } from "../pages/MessageTemplates";
import Setting from "../pages/Setting/Setting";

const MainRoutes = () => {
  const { user } = useAppData();

  return (
    <Routes>
      <Route
        path="/"
        element={
          user ? (
            <Navigate to={ROUTES.OrderStatus.path} />
          ) : (
            <Navigate to={ROUTES.Login.path} />
          )
        }
      />
      {/* <Route path={ROUTES.Home.path} element={<Login />} /> */}
      {/** Protected Routes */}
      {/** Wrap all Route under ProtectedRoutes element */}
      <Route path={"/"} element={<ProtectedRoutes />}>
        <Route path={ROUTES.OrderStatus.path} element={<OrderStatus />} />
        <Route path={ROUTES.AdminAccounts.path} element={<AdminAccount />} />
        <Route path={ROUTES.PatientLookUp.path} element={<PatientLookUp />} />
        <Route
          path={ROUTES.PatientLookUp.path + "/:patientId"}
          element={<PatientDetails />}
        />
        <Route path={ROUTES.Messaging.path} element={<Messaging />} />
        <Route
          path={ROUTES.MessageTemplates.path}
          element={<MessageTemplates />}
        />
        <Route path={ROUTES.BatchHistory.path} element={<BatchHistory />} />
        <Route path={ROUTES.Archive.path} element={<Archive />} />
        <Route path={ROUTES.BatchRules.path} element={<BatchRules />} />
        <Route path={ROUTES.BatchPreview.path} element={<BatchPreview />} />
        <Route path={ROUTES.Settings.path} element={<Setting />} />
      </Route>
      {/** Public Routes */}
      {/** Wrap all Route under PublicRoutes element */}
      <Route path="/" element={<PublicRoutes />}>
        <Route path={ROUTES.Login.path} element={<Login />} />
      </Route>

      {/** Error404 route */}
      {/* <Route path="*" element={<Error404 />} /> */}
    </Routes>
  );
};

export default MainRoutes;
