/** @format */

import {
  ActionIcon,
  Divider,
  Flex,
  Input,
  Select,
  Tooltip,
} from "@mantine/core";
import styled from "styled-components";
import { colors } from "../../../constants";
import { IconPlus, IconX } from "@tabler/icons-react";
import { UseFormReturnType } from "@mantine/form";
import { useAppData } from "../../../contexts/AppContext";
import { slugify } from "../../../modules";
import { Fragment, useEffect, useState } from "react";

const IFS = [
  {
    label: "Medication Contains",
    value: "medication-contains",
  },
  {
    label: "Dosage Form",
    value: "dosage-form",
  },
  {
    label: "Species",
    value: "species",
  },
  {
    label: "DOB",
    value: "dob",
  },
  {
    label: "Dr Last Name",
    value: "drLastName",
  },
  // {
  //   label: "Form",
  //   value: "form",
  // },
  {
    label: "Quantity",
    value: "quantity",
  },
  {
    label: "Price Exceeds",
    value: "price-exceeds",
  },
];

const input_styles = {
  input: {
    borderColor: "#6E23CF",
    borderWidth: "3px",
    backgroundColor: colors.bg,
  },
};

interface Props {
  rule: {
    action: string;
    condition: string;
    conditionValue: string[] | number[];
    secondCondition: any;
  };
  form?: UseFormReturnType<any, (values: any) => any>;
  editable?: boolean;
}

function Rule(props: Props) {
  const {
    batchRulesData: { batchRuleActions },
  } = useAppData();

  const { rule, form, editable = false } = props;
  const [showCondition2, setCondition2] = useState(
    rule?.secondCondition?.value ? true : false
  );

  useEffect(() => {
    if (!showCondition2 && form) {
      form.setFieldValue("secondCondition", {});
    }
  }, [showCondition2, form]);

  useEffect(() => {
    if (!form) {
      setCondition2(rule?.secondCondition?.value ? true : false);
    }
  }, [rule]);

  return (
    <Flex wrap={"wrap"} align={"center"} gap={15}>
      <Text>If</Text>{" "}
      <Select
        readOnly={!editable}
        allowDeselect={false}
        data={IFS}
        styles={input_styles}
        size="lg"
        variant="filled"
        value={rule.condition}
        clearable={false}
        {...form?.getInputProps("condition")}
        // defaultValue={IFS[0].value}
      />
      {rule.conditionValue?.map((value, index) => (
        <Flex style={{ position: "relative" }}>
          <Input
            readOnly={!editable}
            styles={input_styles}
            size="lg"
            variant="filled"
            value={value}
            onChange={(e) => {
              if (form) {
                let value = e.target.value;
                let arr = [...form.values["conditionValue"]];
                arr[index] = value;
                form.setFieldValue("conditionValue", arr);
              }
            }}
          />
          {form && form.values.length > 1 && (
            <div style={{ position: "absolute", right: -3, top: -3 }}>
              <ActionIcon
                onClick={() => {
                  if (form) {
                    let arr = [...form.values["conditionValue"]];
                    arr.splice(index, 1);
                    form.setFieldValue("conditionValue", arr);
                  }
                }}
                size={"sm"}
              >
                <IconX />
              </ActionIcon>
            </div>
          )}
        </Flex>
      ))}{" "}
      {showCondition2 ? (
        <Fragment>
          <Divider size={"md"} orientation="vertical" />
          <Select
            maw={150}
            readOnly={!editable}
            allowDeselect={false}
            data={[
              { label: "AND", value: "AND" },
              { label: "OR", value: "OR" },
            ]}
            fw={"bold"}
            styles={input_styles}
            defaultValue="AND"
            size="lg"
            variant="filled"
            clearable={false}
            value={rule?.secondCondition?.logicalOp}
            {...form?.getInputProps("secondCondition.logicalOp")}
            // value={rule.condition}
            // {...form?.getInputProps("condition")}
          />
          <Divider size={"md"} orientation="vertical" />
          <Select
            readOnly={!editable}
            allowDeselect={false}
            data={IFS}
            // defaultValue={IFS[0].value}
            styles={input_styles}
            size="lg"
            variant="filled"
            value={rule?.secondCondition?.key}
            {...form?.getInputProps("secondCondition.key")}
            clearable={false}
          />
          <Input
            readOnly={!editable}
            styles={input_styles}
            size="lg"
            variant="filled"
            // value={value}
            value={rule?.secondCondition?.value}
            onChange={(e) => {
              // if (form) {
              //   let value = e.target.value;
              //   let arr = [...form.values["conditionValue"]];
              //   arr[index] = value;
              //   form.setFieldValue("conditionValue", arr);
              // }
            }}
            {...form?.getInputProps("secondCondition.value")}
          />
        </Fragment>
      ) : null}
      <Divider size={"md"} orientation="vertical" />
      {editable && (
        <Tooltip
          label={showCondition2 ? "Close condition" : "Add more conditions"}
        >
          <ActionIcon
            style={{ borderWidth: 3 }}
            radius={"md"}
            h={50}
            w={50}
            mt={3}
            variant="outline"
            onClick={() => {
              if (form) {
                console.log("form.values", form.values);
                // let arr = [...form.values["conditionValue"], ""];
                // form.setFieldValue("conditionValue", arr);
              }
              setCondition2(!showCondition2);
            }}
          >
            {showCondition2 ? <IconX /> : <IconPlus />}
          </ActionIcon>
        </Tooltip>
      )}
      <Text>, Then</Text>
      <Select
        readOnly={!editable}
        allowDeselect={false}
        data={batchRuleActions.map((act) => ({
          label: act.title,
          value: act.slug || slugify(act.title),
        }))}
        // defaultValue={batchRuleActions[0].slug}
        styles={input_styles}
        size="lg"
        variant="filled"
        value={rule.action}
        {...form?.getInputProps("action")}
        clearable={false}
      />
    </Flex>
  );
}

export default Rule;

const Text = styled.div`
  font-family: Manrope;
  font-size: 18px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;
