import { useEffect, useState } from "react";
import { AdminCol, UseAdmins } from "../constants";
import {
  addSubcollectionDoc,
  getAllDocsNested,
  upsertDoc,
} from "../repositories/firebase";
import { COLLECTIONS } from "../constants/global";
import { notifications } from "@mantine/notifications";
import { AdminExt } from "../constants/types/extensions";
import axios from "axios";

export const useAdmins = (user?: any): UseAdmins => {
  const [admins, setAdmins] = useState<AdminExt[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getAdmins = async () => {
    try {
      const _admins = await getAllDocsNested<AdminExt>(
        COLLECTIONS.ADMINS,
        "admin-logs"
      );
      setAdmins(_admins);
    } catch (error) {
      console.log("Error getting admins!");
    }
  };

  const saveAdmin = async (
    _admin: AdminExt,
    password?: string
  ): Promise<boolean> => {
    try {
      if (!_admin?.id) {
        const baseUrl =
          "https://us-central1-art-of-med-text-to-order.cloudfunctions.net/app";

        try {
          const response = await axios.post(`${baseUrl}/createUser`, {
            data: {
              email: _admin.email,
              password: password,
              name: _admin.name,
              phone: _admin.phone,
              isActive: _admin.isActive,
              role: _admin.role,
            },
          });

          console.log(response.data);
          notifications.show({
            title: "Admin saved successfully",
            message: "",
            variant: "success",
          });
        } catch (error) {
          console.error("Error creating user:", error);
          notifications.show({
            title: "Failed to create user",
            message: "",
            color: "red",
          });
        }

        getAdmins();
        // add logs
        await addSubcollectionDoc(
          COLLECTIONS.ADMINS,
          user?.uid,
          "admin-logs",
          {
            title: `Admin created.`,
            description: `Admin ${_admin.name} created.`,
          },
          {
            createdAt: true,
          }
        );
        return true;
      } else {
        const { success, code, message } = await upsertDoc<AdminCol>(
          COLLECTIONS.ADMINS,
          {
            email: _admin.email,
            name: _admin.name,
            phone: _admin.phone,
            // status: _admin.status,
            role: _admin.role,
            isActive: _admin.isActive,
          },
          _admin?.id,
          {
            createdAt: true,
            updatedAt: true,
          }
        );
        if (success) {
          console.log("Admin updated successfully!");
          notifications.show({
            title: "Admin updated successfully",
            message: "",
            variant: "success",
          });
          getAdmins();

          // add logs
          await addSubcollectionDoc(
            COLLECTIONS.ADMINS,
            user?.uid,
            "admin-logs",
            {
              title: `Admin ${_admin?.id ? "updated" : "created"}.`,
              description: `Admin ${_admin.name} ${
                _admin?.id ? "updated" : "created"
              }.`,
            },
            {
              createdAt: true,
            }
          );
          return true;
        } else {
          console.log("Error saving admin!", message, code);
          notifications.show({
            title: "Could not save admin",
            message: "",
            color: "red",
          });
          return false;
        }
      }
    } catch (error: any) {
      console.log("error", error);
      notifications.show({
        title: "Error while creating admin.",
        message: "",
        color: "red",
      });
      return false;
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getAdmins();
      setLoading(false);
    })();
  }, []);

  return {
    admins,
    loading,
    getAdmins,
    saveAdmin,
  };
};
