/** @format */

import {
  Button,
  Checkbox,
  Flex,
  Image,
  PasswordInput,
  Space,
  Text,
  TextInput,
} from "@mantine/core";
import styled from "styled-components";
import { assets } from "../../constants";
import { useForm } from "@mantine/form";
import moment from "moment";
import { signIn } from "../../repositories/firebase";
import { ROUTES } from "../../constants/routes";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

interface IUserForm {
  email: string;
  password: string;
  rememberMe: boolean;
}

function Login() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const form = useForm<IUserForm>({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      password: (value) => (value === "" ? "Password required" : null),
    },
  });
  const navigate = useNavigate();

  const login = async (values: IUserForm) => {
    setIsSubmitting(true);
    localStorage.removeItem("lastLoginTime");
    const { success, code } = await signIn(values.email, values.password);
    if (success) {
      // save +1 day time to local storage
      if (values.rememberMe) {
        localStorage.setItem(
          "lastLoginTime",
          moment().add(1, "day").toString()
        );
      }

      navigate(ROUTES.OrderStatus.path, {
        state: {
          loggingIn: true,
        },
      });
    } else {
      if (code === "auth/user-not-found") {
        form.setFieldError("email", "User does not exist");
      } else if (code === "auth/wrong-password") {
        form.setFieldError("password", "Wrong password");
      } else if (code === "auth/invalid-credential") {
        form.setFieldError("email", "Invalid credentials.");
      }
    }
    setIsSubmitting(false);
  };

  return (
    <Container>
      <form onSubmit={form.onSubmit((values) => login(values))}>
        <Flex
          h={"100vh"}
          w={"100vw"}
          direction={"column"}
          align={"center"}
          justify={"space-evenly"}
        >
          <Flex>
            <Image w={133} h={128} src={assets.images.logo} />
          </Flex>
          <Flex
            className="no-scrollbar"
            mah={"65vh"}
            mih={"65vh"}
            direction={"column"}
            align={"center"}
          >
            <TextInput
              withAsterisk
              maw={"500px"}
              radius="md"
              size="md"
              styles={{
                input: {
                  borderColor: "#6E23CF",
                  borderWidth: "2px",
                },
              }}
              name="email"
              placeholder="Email Address"
              {...form.getInputProps("email")}
            />
            <Space h="md" />
            <PasswordInput
              withAsterisk
              w={"100%"}
              radius="md"
              size="md"
              name="password"
              styles={{
                input: {
                  borderColor: "#6E23CF",
                  borderWidth: "2px",
                },
              }}
              placeholder="Password"
              {...form.getInputProps("password")}
            />
            <Space h="md" />

            <Checkbox
              {...form.getInputProps("rememberMe")}
              label={<Text>Remember me</Text>}
            />

            <Space h="md" />

            <Button
              loading={isSubmitting}
              type="submit"
              size="lg"
              radius={"xl"}
              miw={250}
            >
              Login
            </Button>
          </Flex>
        </Flex>
      </form>
    </Container>
  );
}

export default Login;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f8f3f0;
`;
