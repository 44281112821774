/** @format */

import {
  ActionIcon,
  Button,
  Card,
  Flex,
  InputLabel,
  TextInput,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import styled from "styled-components";
import { useAppData } from "../../../contexts/AppContext";
import colors, { input_styles } from "../../../constants/theme";

import { DateInput } from "@mantine/dates";
import { IconX } from "@tabler/icons-react";
import { IPatientChatForm } from "../../../constants";
import { useMessages } from "../../../hooks/useMessages";
import PhoneInput from "react-phone-input-2";
import { formatPhoneNumber } from "../../../constants/global";
const PatientFormInitValues: IPatientChatForm = {
  email: "",
  phone: "",
  firstName: "",
  lastName: "",
  dateOfBirth: undefined,
  address: {
    street: "",
    street2: "",
    city: "",
    state: "",
    postalCode: "",
  },
  internalNotes: "",

  medications: [],
};

interface Props {
  initialValues?: any;
  onSubmit: () => void;
  onPatientUpdate: (updatedData: any) => void;
}

function EditPatientForm({ onSubmit, initialValues, onPatientUpdate }: Props) {
  const {
    adminsData: { saveAdmin, admins },
    patientsData: { getPatients },
  } = useAppData();

  let isEditMode = initialValues !== undefined;

  const [dateOfBirth, setDateOfBirth] = useState(
    initialValues?.dateOfBirth
      ? convertTimestampToDate(initialValues.dateOfBirth)
      : null
  );
  const { updateUser } = useMessages();
  function convertTimestampToDate(timestamp) {
    //check if date is already a date object
    if (timestamp instanceof Date) {
      return timestamp;
    }
    return timestamp ? new Date(timestamp.seconds * 1000) : null;
  }
  const [isSubmitting, setIsSubmitting] = useState(false);
  const form = useForm<IPatientChatForm>({
    initialValues: {
      ...PatientFormInitValues,
      ...(initialValues
        ? {
            email: initialValues?.email ? initialValues?.email : "",
            // name: initialValues?.fullName,
            firstName: initialValues?.firstName ? initialValues?.firstName : "",
            lastName: initialValues?.lastName ? initialValues?.lastName : "",
            phone: initialValues?.phone ? initialValues?.phone : "",
            internalNotes: initialValues?.internalNotes
              ? initialValues?.internalNotes
              : "",
            address: {
              street: initialValues?.address?.street
                ? initialValues?.address?.street
                : "",
              street2: initialValues?.address?.street2
                ? initialValues?.address?.street2
                : "",
              city: initialValues?.address?.city
                ? initialValues?.address?.city
                : "",
              state: initialValues?.address?.state
                ? initialValues?.address?.state
                : "",
              postalCode: initialValues?.address?.postalCode
                ? initialValues?.address?.postalCode
                : "",
            },

            ...initialValues,
          }
        : {}),
    },
    validate: {
      email: (value) => {
        if (!/^\S+@\S+$/.test(value)) return "Invalid email";
        else if (admins.find((admin) => admin.email === value) && !isEditMode) {
          return "Email already exists!";
        } else return null;
      },

      phone: (value) => (value === "" ? "Phone Number required" : null),
      firstName: (value) => (value === "" ? "Name required" : null),
    },
  });
  const [medications, setMedications] = useState(
    initialValues?.order?.medications
  );

  // Function to remove a medication entry
  const removeMedication = (index) => {
    const newMedications = [...medications];
    newMedications.splice(index, 1);
    setMedications(newMedications);
  };
  const handleSavePatient = async (values: IPatientChatForm) => {
    setIsSubmitting(true); // Start the submission process

    try {
      if (isEditMode) {
        values.dateOfBirth = dateOfBirth;
        initialValues.dateOfBirth = dateOfBirth;
        values.phone = formatPhoneNumber(values.phone);

        const updatedValues = {
          ...values,
          id: initialValues.id,
        };

        updateUser(
          values,
          medications,
          initialValues.id,
          initialValues?.order?.id
        )
          .then(() => {
            onPatientUpdate(updatedValues);
            setIsSubmitting(false);
          })
          .catch((error) => {
            console.error("Failed to update user", error);
          });
      }
    } catch (error) {
      console.error("Error saving patient data:", error);
    }
  };
  function handleDobChange(date) {
    setDateOfBirth(date);
  }

  return (
    <form onSubmit={form.onSubmit((values) => handleSavePatient(values))}>
      <Flex direction={"column"} gap={30} p={20}>
        <Flex justify={"space-between"} wrap={"wrap"} gap={25}>
          <Flex direction={"column"} flex={4} gap={25}>
            <Flex gap={10} w={"80%"}>
              <TextInput
                radius={"md"}
                styles={input_styles}
                size="xl"
                placeholder="First Name"
                label="First Name"
                name="firstName"
                {...form.getInputProps("firstName")}
              />
              <TextInput
                radius={"md"}
                styles={input_styles}
                size="xl"
                label="Last Name"
                placeholder="Last Name"
                name="lastName"
                {...form.getInputProps("lastName")}
              />
            </Flex>
            <DateInput
              w={"50%"}
              radius={"md"}
              styles={input_styles}
              size="xl"
              placeholder="Birthday"
              label="Birthday"
              name="birthday"
              value={dateOfBirth}
              onChange={handleDobChange}
              //  {...form.getInputProps("dateOfBirth")}
            />

            <TextInput
              radius={"md"}
              styles={input_styles}
              size="xl"
              label="Address"
              placeholder="Street Address"
              name="addressLine1"
              {...form.getInputProps("address.street")}
            />
            <TextInput
              radius={"md"}
              styles={input_styles}
              size="xl"
              placeholder="Street Address Line 2"
              name="addressLine2"
              {...form.getInputProps("address.street2")}
            />
            <Flex gap={10}>
              <TextInput
                radius={"md"}
                styles={input_styles}
                size="xl"
                placeholder="City"
                name="city"
                {...form.getInputProps("address.city")}
              />
              <TextInput
                radius={"md"}
                styles={input_styles}
                size="xl"
                placeholder="State/Province"
                name="state"
                {...form.getInputProps("address.state")}
              />
            </Flex>
            <TextInput
              radius={"md"}
              w={"50%"}
              styles={input_styles}
              size="xl"
              placeholder="Postal/Zip Code"
              name="zipCode"
              {...form.getInputProps("address.postalCode")}
            />

            <Flex gap={10}>
              <TextInput
                radius={"md"}
                styles={input_styles}
                size="xl"
                placeholder="Email"
                label="Email"
                name="email"
                {...form.getInputProps("email")}
              />

              <Flex direction={"column"}>
                <InputLabel size="xl">Phone Number</InputLabel>
                <PhoneInput
                  inputStyle={{
                    border: `3px solid ${colors.primaryColor}`,
                    borderRadius: 8,
                    height: 59,
                    width: "100%",
                  }}
                  specialLabel=""
                  preferredCountries={["us", "pk"]}
                  country={"us"}
                  {...form.getInputProps("phone")}
                  //   value={this.state.phone}
                  //   onChange={(phone) => this.setState({ phone })}
                />
              </Flex>

              {/* <TextInput
                  radius={"md"}
                  styles={input_styles}
                  size="xl"
                  placeholder="Phone Number"
                  label="Phone Number"
                  name="phoneNumber"
                  {...form.getInputProps("phone")}
                /> */}
            </Flex>
          </Flex>
          <Flex flex={1} />
          <Flex direction={"column"} flex={4} gap={10}>
            <InputLabel size="xl">Medications</InputLabel>
            {medications?.map((row, index) => (
              <Flex gap={10} align={"center"}>
                <Card flex={1}>
                  <Flex justify={"space-between"}>
                    <Title> {row.itemName}</Title>
                    <Subtitle>{+row.quantity}mg</Subtitle>
                  </Flex>
                </Card>
                <ActionIcon
                  variant="transparent"
                  color="black"
                  size={"xl"}
                  onClick={() => removeMedication(index)}>
                  <IconX size={"md"} />
                </ActionIcon>
              </Flex>
            ))}
            <Textarea
              radius={"md"}
              styles={input_styles}
              size="xl"
              placeholder="Internal Notes"
              label="Internal Notes"
              name="internalNotes"
              {...form.getInputProps("internalNotes")}
            />
          </Flex>
        </Flex>
        <Button
          type="submit"
          loading={isSubmitting}
          size="lg"
          radius={"xl"}
          style={{ alignSelf: "center" }}>
          Save
        </Button>
      </Flex>
    </form>
  );
}

export default EditPatientForm;

const Title = styled.div`
  font-family: DM Sans;
  font-size: 22px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
`;

const Subtitle = styled.div`
  font-family: DM Sans;
  font-size: 18px;
  color: #777;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
`;
