/** @format */

import { PageHeader } from "../../components";
import { Card, Flex, LoadingOverlay, em } from "@mantine/core";
import ChatsList from "./components/ChatsList";
import PatientProfile from "./components/PatientProfile";
import { PageWrapper } from "../../layout";
import ChatContainer from "./components/ChatContainer";
import { useMessages } from "../../hooks/useMessages";

import React, { useState, useEffect } from "react";
function Messaging() {
  const { chatUsers, fetchChatUsers, cleanupListeners, updateUsers } =
    useMessages();
  const [selectedUser, setSelectedUser] = useState([]);
  // const [chatUsers, setChatUsers] = useState([]); // Using 'any' to bypass type checking
  const [searchValue, setSearchValue] = useState(""); // State to hold the search input

  const handleUserSelect = (userInfo: any) => {
    setSelectedUser(userInfo);
  };

  const handleThread = (mostRecentThread: any) => {
    console.log("mostRecentThread", mostRecentThread);
    if (selectedUser) {
      const updatedUser = {
        ...selectedUser,
        mostRecentThread: mostRecentThread,
      };
      // setSelectedUser(updatedUser);
      // let id = mostRecentThread?.users[1];

      // const updatedChatUsers = chatUsers.map((user) => {
      //   if (user.id === id) {
      //     // Found the user, update their data
      //     return {
      //       ...user,
      //       mostRecentThread: mostRecentThread,
      //     };
      //   }
      //   // Return all other users unchanged
      //   return user;
      // });
      updateUsers(updatedUser);
    }
  };
  //call chat users
  // useEffect(() => {
  //   async function loadChatUsers() {
  //     console.log("fetching chat users");
  //     const data = await fetchChatUsers();
  //     setChatUsers(data);
  //   }

  //   loadChatUsers();
  // }, []);

  useEffect(() => {
    const initChat = async () => {
      await fetchChatUsers();
    };

    initChat();

    return () => {
      cleanupListeners();
    };
  }, [fetchChatUsers, cleanupListeners]);

  const handleSearchChange = (event: any) => {
    setSearchValue(event.target.value); // Update the state with the new input
  };
  const handlePatientUpdate = (updatedData) => {
    // console.log("Patient updated:", updatedData);
    // if (selectedUser) {
    //   const updatedUser = {
    //     ...selectedUser,
    //     firstName: updatedData.firstName,
    //     lastName: updatedData.lastName,
    //     // Include other properties that need to be updated
    //   };
    //   setSelectedUser(updatedUser);
    //   //update entry in chat users
    //   const updatedChatUsers = chatUsers.map((user) => {
    //     // console.log("user", user.id);
    //     // console.log("updatedData", updatedData.id);
    //     if (user.id === updatedData.id) {
    //       // Found the user, update their data
    //       return {
    //         ...user,
    //         firstName: updatedData.firstName,
    //         lastName: updatedData.lastName,
    //         email: updatedData.email,
    //         phone: updatedData.phone,
    //         address: updatedData.address,
    //         dateOfBirth: updatedData.dateOfBirth,
    //         internalNotes: updatedData.internalNotes,
    //       };
    //     }
    //     // Return all other users unchanged
    //     return user;
    //   });
    //   setChatUsers(updatedChatUsers);
    // }
  };
  return (
    <PageWrapper>
      <PageHeader
        searchProps={{
          placeholder: "Search",
          // value: searchValue,
          onChange: handleSearchChange,
        }}
      />
      <Flex w={"100%"} h={"90%"} gap={20}>
        <Card
          className="card-shadow"
          style={{ overflowY: "auto" }}
          shadow="lg"
          padding="lg"
          radius="lg"
          withBorder
          flex={2}
        >
          <LoadingOverlay visible={chatUsers.length == 0 ? true : false} />

          <ChatsList handleSelect={handleUserSelect} chatUsers={chatUsers} />
        </Card>
        <Card
          className="card-shadow"
          shadow="lg"
          padding="lg"
          radius="lg"
          flex={3}
          withBorder
        >
          <ChatContainer
            userInfo={selectedUser}
            searchValue={searchValue}
            mostRecentThread={handleThread}
          />
        </Card>
        <Card
          shadow="lg"
          className="card-shadow"
          padding="lg"
          radius="lg"
          flex={2}
          withBorder
          style={{ overflowY: "auto" }}
        >
          <PatientProfile
            userInfo={selectedUser}
            onPatientUpdate={handlePatientUpdate}
          />
        </Card>
      </Flex>
    </PageWrapper>
  );
}
export default Messaging;
