import {
  Button,
  Flex,
  InputLabel,
  PasswordInput,
  Select,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import styled from "styled-components";
import { useAppData } from "../../../contexts/AppContext";
import colors, { input_styles } from "../../../constants/theme";
import { AdminExt } from "../../../constants";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import {
  getAuth,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
} from "firebase/auth";

const ADMIN_TYPES = [
  { value: "super-admin", label: "Super Admin" },
  { value: "admin", label: "Admin" },
];

interface IAdminForm {
  email: string;
  password?: string;
  type: "admin" | "super-admin";
  confirmPassword?: string;
  phoneNumber: string;
  name: string;
  currentPassword?: string;
}

const AdminFormInitValues: IAdminForm = {
  email: "",
  password: "",
  confirmPassword: "",
  name: "",
  phoneNumber: "",
  type: "super-admin",
  currentPassword: "",
};

interface Props {
  initialValues?: AdminExt;
  onSubmit: () => void;
}

function AddAdminForm({ onSubmit, initialValues }: Props) {
  const {
    adminsData: { saveAdmin, admins },
  } = useAppData();

  let isEditMode = initialValues !== undefined;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const form = useForm<IAdminForm>({
    initialValues: {
      ...AdminFormInitValues,
      ...(initialValues
        ? {
            email: initialValues.email,
            name: initialValues.name,
            phoneNumber: initialValues.phone,
            type: initialValues.role as any,
          }
        : {}),
    },
    validate: {
      email: (value) => {
        if (!/^\S+@\S+$/.test(value)) return "Invalid email";
        else if (admins.find((admin) => admin.email === value) && !isEditMode) {
          return "Email already exists!";
        } else return null;
      },
      password: (value) =>
        value && value.length < 6
          ? "Password must be at least 6 characters"
          : null,
      confirmPassword: (value, values) => {
        if (values.password && values.password !== value) {
          return "Confirm password should be same as password";
        } else {
          return null;
        }
      },
      phoneNumber: (value) => (value === "" ? "Phone Number required" : null),
      name: (value) => (value === "" ? "Name required" : null),
      currentPassword: (value) => {
        if (isEditMode && form.values.password && value === "") {
          return "Current password required to update password";
        }
        return null;
      },
    },
  });

  const handleInputChange = () => {
    setHasChanges(true);
  };

  const handleSaveAdmin = async (values: IAdminForm) => {
    const validationErrors = form.validate();

    if (Object.keys(validationErrors.errors).length > 0) {
      return;
    }

    if (isEditMode && values.password) {
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        const credential = EmailAuthProvider.credential(
          values.email,
          values.currentPassword!
        );
        await reauthenticateWithCredential(user, credential);
        setCurrentPasswordError("");
      } catch (error) {
        setCurrentPasswordError("Current password is incorrect");
        return;
      }
    }

    setIsSubmitting(true);
    const success = await saveAdmin(
      {
        id: initialValues?.id,
        email: values.email,
        name: values.name,
        phone: values.phoneNumber,
        isActive: true,
        role: values.type,
      },
      values.password
    );

    if (success) {
      if (isEditMode && values.password) {
        try {
          const auth = getAuth();
          const user = auth.currentUser;
          await updatePassword(user, values.password);
        } catch (error) {
          console.error("Error updating password:", error);
        }
      }
      onSubmit();
    }
    setIsSubmitting(false);
  };

  return (
    <form onSubmit={form.onSubmit((values) => handleSaveAdmin(values))}>
      <Flex direction={"column"} w={"100%"} gap={30}>
        <Title>{isEditMode ? "Edit Admin" : "Add New Admin"}</Title>
        <Flex justify={"space-between"} gap={35}>
          <Flex direction={"column"} flex={1} gap={20}>
            <Select
              data={ADMIN_TYPES}
              defaultValue={"super-admin"}
              styles={input_styles}
              size="xl"
              label="Account Type"
              radius={"md"}
              {...form.getInputProps("type")}
              onChange={(value) => {
                form.setFieldValue("type", value as "super-admin" | "admin");
                handleInputChange();
              }}
            />
            <TextInput
              radius={"md"}
              styles={input_styles}
              size="xl"
              label="Email"
              disabled={isEditMode}
              name="email"
              {...form.getInputProps("email")}
              onChange={(event) => {
                form.setFieldValue("email", event.currentTarget.value);
                handleInputChange();
              }}
            />
            {isEditMode && form.values.password && (
              <PasswordInput
                radius={"md"}
                styles={input_styles}
                size="xl"
                label="Current Password"
                {...form.getInputProps("currentPassword")}
                error={currentPasswordError}
                onChange={(event) => {
                  form.setFieldValue(
                    "currentPassword",
                    event.currentTarget.value
                  );
                  handleInputChange();
                }}
              />
            )}
            <PasswordInput
              radius={"md"}
              styles={input_styles}
              size="xl"
              label="Password"
              {...form.getInputProps("password")}
              onChange={(event) => {
                form.setFieldValue("password", event.currentTarget.value);
                handleInputChange();
              }}
            />
          </Flex>
          <Flex direction={"column"} flex={1} gap={20}>
            <TextInput
              radius={"md"}
              styles={input_styles}
              size="xl"
              label="Full Name"
              {...form.getInputProps("name")}
              onChange={(event) => {
                form.setFieldValue("name", event.currentTarget.value);
                handleInputChange();
              }}
            />
            <Flex direction={"column"}>
              <InputLabel size="xl">Phone Number</InputLabel>
              <PhoneInput
                inputStyle={{
                  border: `3px solid ${colors.primaryColor}`,
                  borderRadius: 8,
                  height: 59,
                  width: "100%",
                }}
                containerStyle={{}}
                specialLabel=""
                preferredCountries={["us", "pk"]}
                country={"us"}
                value={form.values.phoneNumber}
                onChange={(value) => {
                  form.setFieldValue("phoneNumber", value);
                  handleInputChange();
                }}
              />
            </Flex>
            <PasswordInput
              radius={"md"}
              styles={input_styles}
              size="xl"
              label="Confirm Password"
              {...form.getInputProps("confirmPassword")}
              error={form.errors.confirmPassword}
              onChange={(event) => {
                form.setFieldValue(
                  "confirmPassword",
                  event.currentTarget.value
                );
                handleInputChange();
              }}
            />
          </Flex>
        </Flex>
        <Button
          type="submit"
          loading={isSubmitting}
          size="lg"
          radius={"xl"}
          disabled={isEditMode && !hasChanges}
          style={{ alignSelf: "center" }}>
          {isEditMode ? "Update" : "Add New Admin"}
        </Button>
      </Flex>
    </form>
  );
}

export default AddAdminForm;

const Title = styled.div`
  font-family: DM Sans;
  font-size: 22px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
`;
