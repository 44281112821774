import {
  Button,
  Checkbox,
  Flex,
  Image,
  LoadingOverlay,
  Table,
  Modal,
} from "@mantine/core";
import styled from "styled-components";
import { CustomPagination, PageHeader } from "../../components";
import { Fragment, useRef, useState } from "react";
import {
  BatchRuleActionType,
  BatchRuleConditionType,
  OrderStatus,
  assets,
  colors,
} from "../../constants";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { PageWrapper } from "../../layout";
import Papa from "papaparse";
import { useAppData } from "../../contexts/AppContext";
import moment from "moment";
import { highlightedText } from "../../modules/highlightedText";
import { chunk, displayPaginationMessage } from "../../modules";
import { PAGINATION_SIZE } from "../../constants/global";
import { notifications } from "@mantine/notifications";
import dayjs from "dayjs";
import {useDisclosure} from "@mantine/hooks";

function BatchHistory() {
  const navigate = useNavigate();
  const [searchText, setSearchText] = useState("");
  const [openErrorPopupStatus, { open, close }] = useDisclosure(false);
  type InvalidRow = {
    rowIndex: number;
    invalidFields: string[];
    rowData: Record<string, any>;
  };

  const [invalidRowsData, setInvalidRowsData] = useState<InvalidRow[]>([]);
  const {
    batchPreviewData: { batchPreview, loading },
    batchRulesData: { batchRules },
  } = useAppData();
  const [activePage, setPage] = useState(1);
  const _batchPreview = chunk(batchPreview, PAGINATION_SIZE);

  const onBatchRulesClick = () => {
    navigate(ROUTES.BatchRules.path);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target!.result as string;
        parseCsvData(text);
      };
      reader.readAsText(file);
    }
  };

  const onBatchPreviewUpload = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const parseCsvData = (text) => {
    Papa.parse(text, {
      header: true,
      skipEmptyLines: true,
      complete: async (results) => {
        // Function to check if a value is invalid
        const isInvalid = (value) => value === undefined || value === "";
        const isRowEmpty = (row) => Object.values(row).every(value => value === "");
        // Convert headers to lowercase for case-insensitive processing
        const convertToLowercaseKeys = (obj) => {
          return Object.keys(obj).reduce((acc, key) => {
            acc[key.toLowerCase()] = obj[key];
            return acc;
          }, {});
        };

        // Map data and convert keys to lowercase
        let data = results.data
            .filter(row => !isRowEmpty(row))
            .map((datum) => {
              const lowerDatum = convertToLowercaseKeys(datum);

              // Error handling for missing essential data
              if (
                  !lowerDatum["rx number"] ||
                  !lowerDatum["first name"] ||
                  !lowerDatum["last name"]
              ) {
                console.error(
                    "Missing essential customer information",
                    lowerDatum
                );
                return null; // or handle this case differently based on your application's requirements
              }

              const orderDate = dayjs(
                  lowerDatum["order date"],
                  "MM/DD/YYYY"
              ).toDate(); // Using day.js instead of moment
              // const dob = dayjs(lowerDatum["dob"], "MM/DD/YYYY")
              //   .startOf("day")
              //   .toDate(); // Ensuring time is set to start of the day
              const dob = moment.utc(lowerDatum["dob"], "MM/DD/YYYY").hour(12).toDate();
              // Ensuring the date is parsed in utc and added 12 hours so noon | to be on safer side of timezone issue
              return {
                rxNumber: lowerDatum["rx number"],
                firstName: lowerDatum["first name"],
                lastName: lowerDatum["last name"],
                orderDate: orderDate,
                dateOfBirth: dob,
                phone: formatPhoneNumber(lowerDatum["cell"]),
                species: lowerDatum["species"],
                doctor:
                    (lowerDatum["dr first name"] || "") +
                    " " +
                    (lowerDatum["dr last name"] || ""),
                med: lowerDatum["medication"],
                dosage_form: lowerDatum["form"],
                qty: lowerDatum["qty"],
                price: lowerDatum["price"],
                orderStatus: OrderStatus.WaitingResponse,
                alt_qty: "",
                alt_price: "",
                memo: lowerDatum["memo"] ?? '', // will be use to auto fill [auto refill , alt qty , alt price]
                authRefills: lowerDatum["auth. refills"] ?? '', // just for preview related
                metadata: {},
              };
            })
            .filter((item) => item !== null); // Filtering out null entries from the array

        console.log("data", data);

        // Collect information about invalid rows
        let invalidRows = results.data
            .map((datum, index) => {
              const lowerDatum = convertToLowercaseKeys(datum);
              let invalidFields = Object.keys(lowerDatum).filter(
                  (key) =>
                      isInvalid(lowerDatum[key]) &&
                      key !== "alt_qty" &&
                      key !== "alt_price" &&
                      key !== "auth. refills" &&
                      key !== "memo"
              );

              if (invalidFields.length > 0) {
                return {
                  rowIndex: index + 2,
                  invalidFields,
                  rowData: lowerDatum,
                };
              }
              return null;
            })
            .filter((row) => row !== null);

        if (invalidRows.length > 0) {
          setInvalidRowsData(invalidRows);
          open();
          inputRef.current.value = "";
          //add the code here to display those invalid rows and higlight the fields that are invalid
          notifications.show({
            title: `${invalidRows.length} row(s) contain corrupt/invalid data, skipping...`,
            message: invalidRows
                .map(
                    (row) =>
                        `Row ${
                            row.rowIndex
                        } has invalid data in fields: ${row.invalidFields.join(", ")}`
                )
                .join("\n"),
            variant: "warning",
            autoClose: 10000,
          });
          return;
        }

        // Filter out invalid rows
        data = data.filter(
          (datum) =>
            !Object.keys(datum).some(
              (key) =>
                isInvalid(datum[key]) &&
                key !== "alt_qty" &&
                key !== "alt_price" && key !== "authRefills" && key !== "memo"
            )
        );

        // Apply batch rules
        batchRules.forEach((rule) => {
          data = data.map((d) => {
            let ruleMatch = false;

            switch (rule.conditionType) {
              case BatchRuleConditionType.MedicationContains:
                ruleMatch = rule.conditionValue?.some((v) =>
                  d.med.toLowerCase().includes(v.toLowerCase())
                );
                break;
              case BatchRuleConditionType.DosageForm:
                ruleMatch = rule.conditionValue?.some((v) =>
                  d.dosage_form.toLowerCase().includes(v.toLowerCase())
                );
                break;
              case BatchRuleConditionType.Quantity:
                ruleMatch = rule.conditionValue?.some(
                  (v) => parseFloat(d.qty) === parseFloat(v)
                );
                break;
              case BatchRuleConditionType.DrLastName:
                ruleMatch = rule.conditionValue?.some((v) =>
                  d.doctor.toLowerCase().includes(v.toLowerCase())
                );
                break;
              case BatchRuleConditionType.PriceExceeds:
                ruleMatch = rule.conditionValue?.some(
                  (v) => parseFloat(d.price.replace("$", "")) > parseFloat(v)
                );
                break;
              case BatchRuleConditionType.DOB:
                ruleMatch = rule.conditionValue?.some((v) =>
                  moment(d.dateOfBirth).isSame(moment(v, "MM/DD/YYYY"), "day")
                );
                break;
              case BatchRuleConditionType.SPECIE:
                ruleMatch = rule.conditionValue?.some((v) =>
                  d.species.toLowerCase().includes(v.toLowerCase())
                );
                break;
              default:
                break;
            }

            if (ruleMatch) {
              if (rule.secondCondition) {
                let secondConditionMatch = false;
                switch (rule.secondCondition.key) {
                  case BatchRuleConditionType.DosageForm:
                    secondConditionMatch =
                      d.dosage_form === rule.secondCondition.value;
                    break;
                  case BatchRuleConditionType.MedicationContains:
                    if (typeof rule.secondCondition.value === "string") {
                      secondConditionMatch = d.med
                        .toLowerCase()
                        .includes(rule.secondCondition.value.toLowerCase());
                    }
                    break;
                  case BatchRuleConditionType.Quantity:
                    secondConditionMatch =
                      parseFloat(d.qty) ===
                      parseFloat(rule.secondCondition.value);
                    break;
                  case BatchRuleConditionType.DrLastName:
                    if (typeof rule.secondCondition.value === "string") {
                      secondConditionMatch = d.doctor
                        .toLowerCase()
                        .includes(rule.secondCondition.value.toLowerCase());
                    }
                    break;
                  case BatchRuleConditionType.PriceExceeds:
                    if (typeof rule.secondCondition.value === "string") {
                      secondConditionMatch =
                        parseFloat(d.price.replace("$", "")) >
                        parseFloat(rule.secondCondition.value);
                    }
                    break;
                  case BatchRuleConditionType.DOB:
                    if (typeof rule.secondCondition.value === "string") {
                      secondConditionMatch = moment(d.dateOfBirth).isSame(
                        moment(rule.secondCondition.value, "MM/DD/YYYY"),
                        "day"
                      );
                    }
                    break;
                  case BatchRuleConditionType.SPECIE:
                    if (typeof rule.secondCondition.value === "string") {
                      secondConditionMatch =
                        d.species?.toLowerCase() ===
                        rule.secondCondition.value.toLowerCase();
                    }
                    break;
                }
                if (
                  rule.secondCondition.logicalOp === "AND" &&
                  !secondConditionMatch
                ) {
                  ruleMatch = false;
                } else if (
                  rule.secondCondition.logicalOp === "OR" &&
                  secondConditionMatch
                ) {
                  ruleMatch = true;
                }
              }
              if (ruleMatch) {
                d.metadata = {
                  ...d.metadata,
                  [rule.action.title]: true,
                };
              }
            }

            return d;
          });
        });
        //data modify logic
        data = data.map((item) => {
          if (item?.memo?.trim()) {
              let tmpMemo = item.memo?.toLowerCase();
              let splitMemo = tmpMemo.split(',');
              let status = null, alt_qnt = null, alt_price = null;
              if (splitMemo.length === 3) {
                  [status,alt_qnt,alt_price] = splitMemo;
              } else if (splitMemo.length === 2) {
                  [alt_qnt,alt_price] = splitMemo;
              }else if (splitMemo.length === 1) {
                  [status] = splitMemo;
              }
              if (alt_qnt) {
                  item.alt_qty = alt_qnt.trim();
              }
              if (alt_price) {
                  item.alt_price = alt_price.trim();
              }
              if(status==="arf") {
                  item.metadata = {
                      ...item.metadata,
                      "auto-refill": true
                  };
              }
          }
          return item;
        });

        if (data.length === 0) {
          notifications.show({
            title: "No valid data to process",
            message: "Please check your CSV file and try again.",
            variant: "error",
            autoClose: 5000,
          });
          inputRef.current.value = "";
          return;
        }
        inputRef.current.value = "";
        navigate(ROUTES.BatchPreview.path, {state: {data}});
      },
      error: (error) => {
        inputRef.current.value = "";
        console.error("Error parsing CSV:", error);
        notifications.show({
          title: "Error parsing CSV",
          message: error.message,
          variant: "error",
          autoClose: 5000,
        });
      },
    });
  };

  const searchFilter = (data: any[], text: string) =>
    data.filter(
      (d) =>
        (d.firstName + " " + d.lastName)
          .toLowerCase()
          .includes(text.toLowerCase()) ||
        moment(d.dateOfBirth).format("MM/DD/YYYY").includes(text.toLowerCase())
    );

  let filteredBatch = searchFilter(batchPreview, searchText);
  function formatPhoneNumber(phoneNumber) {
    if (!phoneNumber) return;
    // Remove non-numeric characters
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, "");

    // Check if the length is 10 and prepend "+1"
    if (cleanedPhoneNumber.length === 10) {
      return `+1${cleanedPhoneNumber}`;
    }
    // Check if the length is 11 and starts with "1", then prepend "+"
    else if (
      cleanedPhoneNumber.length === 11 &&
      cleanedPhoneNumber.startsWith("1")
    ) {
      return `+${cleanedPhoneNumber}`;
    }

    // Return the cleaned phone number as is if none of the above conditions are met
    return cleanedPhoneNumber;
  }

  const rows = (
    searchText ? filteredBatch : _batchPreview[activePage - 1] || []
  )?.map((b) => (
    <Table.Tr key={b.id}>
      <Table.Td>{highlightedText(b.firstName, searchText)}</Table.Td>
      <Table.Td>{highlightedText(b.lastName, searchText)}</Table.Td>
      <Table.Td>{b.species}</Table.Td>
      <Table.Td>
        {highlightedText(
          moment(b.dateOfBirth).format("MM/DD/YYYY"),
          searchText
        )}
      </Table.Td>
      <Table.Td>{b.phone}</Table.Td>
      <Table.Td>{b.doctor}</Table.Td>
      <Table.Td>{b.med}</Table.Td>
      <Table.Td>{b.dosage_form}</Table.Td>
      <Table.Td>{b.qty}</Table.Td>
      <Table.Td>{b.price}</Table.Td>
      <Table.Td>{b.memo}</Table.Td>
      <Table.Td>{b.authRefills}</Table.Td>
      <Table.Td>
        {/* <Checkbox
          value={BatchRuleActionType.AltQuantity}
          checked={b?.metadata && b.metadata[BatchRuleActionType.AltQuantity]}
        /> */}
        {b.alt_qty}
      </Table.Td>
      <Table.Td>
        {/* <Checkbox
          value={BatchRuleActionType.AltPrice}
          checked={b?.metadata && b.metadata[BatchRuleActionType.AltPrice]}
        /> */}
        {b.alt_price}
      </Table.Td>
      <Table.Td>
        {/* {b?.metadata && b.metadata[BatchRuleActionType.RequireSignature] ? (
          <IconCheckbox />
        ) : null} */}
        <Checkbox
          readOnly
          // value={BatchRuleActionType.RequireSignature}
          defaultChecked={
            b?.metadata && b.metadata[BatchRuleActionType.RequireSignature]
          }
        />
      </Table.Td>
      <Table.Td>
        <Checkbox
          // value={BatchRuleActionType.AutoRefill}
          readOnly
          defaultChecked={
            b?.metadata && b.metadata[BatchRuleActionType.AutoRefill]
          }
        />
      </Table.Td>
      <Table.Td>
        <Checkbox
          // value={BatchRuleActionType.Overnight}
          readOnly
          defaultChecked={
            b?.metadata && b.metadata[BatchRuleActionType.Overnight]
          }
        />
      </Table.Td>
      <Table.Td>
        <Checkbox
          readOnly
          // value={BatchRuleActionType.MustUseUPS}
          defaultChecked={
            b?.metadata && b.metadata[BatchRuleActionType.MustUseUPS]
          }
        />
      </Table.Td>
      <Table.Td>
        <Checkbox
          // value={BatchRuleActionType.CheckForPregnantBreastfeeding}
          readOnly
          defaultChecked={
            b?.metadata &&
            b.metadata[BatchRuleActionType.CheckForPregnantBreastfeeding]
          }
        />
      </Table.Td>

      {/* no payment */}

      <Table.Td>
        <Checkbox
          // value={BatchRuleActionType.NoPayment}
          readOnly
          defaultChecked={
            b?.metadata && b.metadata[BatchRuleActionType.NoPayment]
          }
        />
      </Table.Td>

      {/* Human Flavour */}
      <Table.Td>
        <Checkbox
          // value={BatchRuleActionType.HumanMedicineFlavour}
          readOnly
          defaultChecked={
            b?.metadata && b.metadata[BatchRuleActionType.HumanMedicineFlavour]
          }
        />
      </Table.Td>

      {/* dog flavour */}
      <Table.Td>
        <Checkbox
          // value={BatchRuleActionType.DogMedicineFlavour}
          readOnly
          defaultChecked={
            b?.metadata && b.metadata[BatchRuleActionType.DogMedicineFlavour]
          }
        />
      </Table.Td>
      {/* dog treat flavour  */}
      <Table.Td>
        <Checkbox
          // value={BatchRuleActionType.DogTreatFlavour}
          readOnly
          defaultChecked={
            b?.metadata && b.metadata[BatchRuleActionType.DogTreatFlavour]
          }
        />
      </Table.Td>

      {/* cat flavour  */}
      <Table.Td>
        <Checkbox
          // value={BatchRuleActionType.CatMedicineFlavour}
          readOnly
          defaultChecked={
            b?.metadata && b.metadata[BatchRuleActionType.CatMedicineFlavour]
          }
        />
      </Table.Td>

      {/* cat treat flavour  */}
      <Table.Td>
        <Checkbox
          // value={BatchRuleActionType.CatTreatFlavour}
          readOnly
          defaultChecked={
            b?.metadata && b.metadata[BatchRuleActionType.CatTreatFlavour]
          }
        />
      </Table.Td>

      {/* rodent / bird flavour  */}

      <Table.Td>
        <Checkbox
          // value={BatchRuleActionType.RodentBirdMedicineFlavour}
          readOnly
          defaultChecked={
            b?.metadata &&
            b.metadata[BatchRuleActionType.RodentBirdMedicineFlavour]
          }
        />
      </Table.Td>
    </Table.Tr>
  ));
  return (
      <>
        <Modal opened={openErrorPopupStatus} onClose={close} title="Invalid Data Rows" size="xl">
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>Row</Table.Th>
                <Table.Th>Invalid Fields</Table.Th>
                <Table.Th>Invalid Row Data</Table.Th>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {invalidRowsData.filter((row) => row.invalidFields.length > 0).map((row, index) => (
                  <Table.Tr key={index}>
                    <Table.Td>{row.rowIndex}</Table.Td>
                    <Table.Td>
                      {row.invalidFields.map((field, fieldIndex) => (
                          <span key={fieldIndex} style={{ color: 'red', fontWeight: 'bold' }}>
                {field}{fieldIndex < row.invalidFields.length - 1 ? ', ' : ''}
              </span>
                      ))}
                    </Table.Td>
                    <Table.Td>
                      {Object.entries(row.rowData).filter(([key, value]) => row.invalidFields.includes(key)).map(([key, value], dataIndex) => (
                          <div key={dataIndex}>
                            <span style={{ fontWeight: 'bold' }}>{key}: </span>
                            <span style={{ color: 'red' }}>
                  {String(value) || 'N/A'}
                </span>
                          </div>
                      ))}
                    </Table.Td>
                  </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </Modal>
    <PageWrapper
      footer={
        <CustomPagination
          activePage={activePage}
          setPage={setPage}
          total={_batchPreview?.length}
          message={displayPaginationMessage(activePage, _batchPreview)}
        />
      }
    >
      <PageContainer>
        <PageHeader
            subtitle={displayPaginationMessage(activePage, _batchPreview)}
            searchProps={{
              placeholder: "Search",
              value: searchText,
              onChange: (e) => setSearchText(e.target.value),
            }}
            buttons={
              <Fragment>
                <Button
                    onClick={() =>
                        navigate(ROUTES.BatchPreview.path, {
                          state: {data: batchPreview, update: true},
                        })
                    }
                    radius={"xl"}
                    size="xl"
                >
                  <Image w={25} h={25} src={assets.icons.historyWhite}/>
                </Button>
                <Button onClick={onBatchRulesClick} radius={"xl"} size="xl">
                  Batch Rules
                </Button>
                <Button onClick={onBatchPreviewUpload} radius={"xl"} size="xl">
                  Upload Batch
                </Button>
                <input
                    ref={inputRef}
                    type="file"
                    accept={".csv"}
                    onChange={handleFileChange}
                    style={{display: "none"}}
                />
              </Fragment>
            }
        />

        <Flex mah={"65vh"} style={{overflowY: "auto", overflowX: "auto"}}>
          <LoadingOverlay visible={loading}/>
          <Table
              highlightOnHover
              withTableBorder
              withColumnBorders
              styles={{
                th: {
                  height: 100,
                },
              }}
          >
            <Table.Thead
                bg={colors.bg}
                style={{position: "sticky", top: 0, zIndex: 200}}
            >
              <Table.Tr>
                <Table.Th>FIRST NAME</Table.Th>
                <Table.Th>LAST NAME</Table.Th>
                <Table.Th>SPECIES</Table.Th>
                <Table.Th>DOB</Table.Th>
                <Table.Th>CELL</Table.Th>
                <Table.Th>DR</Table.Th>
                <Table.Th>MED</Table.Th>
                <Table.Th>DOSAGE FORM</Table.Th>
                <Table.Th>QTY</Table.Th>
                <Table.Th>PRICE</Table.Th>
                <Table.Th>Memo</Table.Th>
                <Table.Th>Refill</Table.Th>
                <Table.Th>ALTER QTY</Table.Th>
                <Table.Th>ALT PRICE</Table.Th>
                <RotatedTh>DELIVERY SIG</RotatedTh>
                <RotatedTh>AUTO REFILL</RotatedTh>
                <RotatedTh>OVER NIGHT</RotatedTh>
                <RotatedTh>MUST UPS</RotatedTh>
                <RotatedTh>PREG / BF</RotatedTh>
                <RotatedTh>No Payment</RotatedTh>
                <RotatedTh>Human Flavor</RotatedTh>
                <RotatedTh>Dog Flavor</RotatedTh>
                <RotatedTh>Dog Treat Flavor</RotatedTh>
                <RotatedTh>Cat Flavor</RotatedTh>
                <RotatedTh>Cat Treat Flavor</RotatedTh>
                <RotatedTh>Rodent Bird Flavor</RotatedTh>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>{rows}</Table.Tbody>
          </Table>
        </Flex>
      </PageContainer>
    </PageWrapper>
      </>
  );
}

export default BatchHistory;

const PageContainer = styled.div`
  height: 100%;
  width: 100%;
`;
const RotatedTh = styled.th`
  transform: rotate(-45deg);
  font-size: 12px;
`;
