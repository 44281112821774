/** @format */

import { Badge, Flex, Input, rem } from "@mantine/core";
import { colors } from "../../../constants";
import styled from "styled-components";
// import { useAuth } from "../../../hooks/useAuth";
// import { usePatients } from "../../../hooks/usePatients";
// import { useMessages } from "../../../hooks/useMessages";
// import { useAppData } from "../../../contexts/AppContext";
import moment from "moment";

import React, { useState, useEffect } from "react";
interface Props {
  handleSelect: (patient: object) => void;
  chatUsers: any[];
}
function ChatsList({ handleSelect, chatUsers }: Props) {
  // const patientsData = usePatients();
  // const { loading, messages, getMessages } = useMessages();

  const [selectedPatientId, setSelectedPatientId] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const handleChatItemClick = async (patient: any) => {
    if (patient !== undefined) {
      setSelectedPatientId(patient.id);
      // getMessages(patient.id);
      console.log("selectedPatientId", patient?.mostRecentThread?.lastMessage);
      handleSelect(patient);
    }
  };
  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

  const filteredPatients = searchQuery
    ? chatUsers.filter((patient) =>
        `${patient.firstName} ${patient.lastName}`
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      )
    : chatUsers;
  const formatDate = (timestamp) => {
    if (timestamp instanceof Date) {
      return moment(timestamp).format("MMMM D, h:mm A");
    }
    const date = timestamp.toDate();
    return moment(date).format("MMMM D, h:mm A");
  };
  function truncateText(text, length) {
    if (!text) return text;
    return text.length > length ? text.substr(0, length - 1) + "..." : text;
  }
  useEffect(() => {
    if (chatUsers.length > 0) {
      if (selectedPatientId === "") {
        handleChatItemClick(chatUsers[0]); // Automatically select the first patient
      }
    }
  }, [chatUsers]); // This effect depends on chatUsers

  return (
    <Flex direction={"column"} gap={20}>
      <Input
        variant="filled"
        w={"100%"}
        placeholder="Search"
        size="lg"
        radius={"xl"}
        value={searchQuery}
        onChange={handleSearchChange}
        styles={{
          input: {
            background: "transparent",
          },
          wrapper: {
            borderRadius: rem("44px"),
          },
        }}
      />
      <Flex gap={10} direction={"column"}>
        {filteredPatients.map((patient) => {
          let isActive = patient.id === selectedPatientId;
          return (
            <ChatItem
              key={patient?.id}
              style={{ backgroundColor: isActive ? colors.bg : undefined }}
              onClick={() => handleChatItemClick(patient)}
            >
              <Flex justify={"space-between"}>
                <Text style={{ fontWeight: "500" }}>
                  {patient?.firstName} {patient?.lastName}
                </Text>
                <small>
                  {patient?.mostRecentThread?.lastMessageTimestamp
                    ? formatDate(
                        patient?.mostRecentThread?.lastMessageTimestamp
                      )
                    : ""}
                </small>
              </Flex>

              <Flex justify={"space-between"}>
                <Text>
                  {truncateText(patient?.mostRecentThread?.lastMessage, 50)}
                </Text>
                {patient?.mostRecentThread?.unreadMessages > 0 ? (
                  <Badge size="lg" circle radius={"xl"}>
                  {patient?.mostRecentThread?.unreadMessages}
                  </Badge>
                ) : null}
              
              </Flex>
            </ChatItem>
          );
        })}
        {/* 
				<ChatItem style={{}}>
					<Flex justify={"space-between"}>
						<Text style={{ fontWeight: "500" }}>Ashley Apple</Text>
						<Text>12:12 pm</Text>
					</Flex>
					<Flex justify={"space-between"}>
						<Text>Thanks!</Text>
					</Flex>
				</ChatItem> */}
      </Flex>
    </Flex>
  );
}

export default ChatsList;

const ChatItem = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 15px;

  border-radius: 15px;
  padding: 10px;
  cursor: pointer;
`;

const Text = styled.div`
  font-family: Inter;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;
