export const highlightedText = (text: string = "", search: string) => {
  // Ensure text is a string
  if (typeof text !== "string") {
    console.error(
      "highlightedText function expected a string but received:",
      typeof text
    );
    return []; // or return [String(text)] to convert to string and return as single-element array
  }

  return text.split(new RegExp(`(${search})`, "gi")).map((text, index) =>
    text?.toLowerCase() === search?.toLowerCase() ? (
      <span
        key={index}
        style={{
          color: "#101828",
          backgroundColor: "#FFFF00", // Highlighted background color
        }}>
        {text}
      </span>
    ) : (
      text
    )
  );
};
