import { useEffect, useState } from "react";
import { UsePatients, User } from "../constants";
import {
  upsertDoc,
  subscribeToAllDocs,
  getAllDocsNested,
} from "../repositories/firebase";
import { COLLECTIONS } from "../constants/global";
import { convertTimestamp } from "../modules";

export const usePatients = (): UsePatients => {
  const [patients, setPatients] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getPatients = async () => {
    setLoading(true);
    try {
      const _patients = await getAllDocsNested<User>(COLLECTIONS.USERS);

      setPatients(
        _patients.map((d) => ({
          ...d,
          dateOfBirth: convertTimestamp(d.dateOfBirth as any),
        }))
      );
      setLoading(false);
      return _patients;
    } catch (error) {
      console.log("Error getting patients!", error);
      setLoading(false);
      return [];
    }
  };

  const savePatient = async (
    _patient: User,
    options: { refetch: boolean } = { refetch: true }
  ): Promise<any> => {
    const { success, code, message, data } = await upsertDoc<User>(
      COLLECTIONS.USERS,
      {
        ..._patient,
      },
      _patient?.id
    );
    if (success) {
      if (options?.refetch) {
        getPatients();
      }
      return {
        id: data?.id,
        key: `${_patient.firstName}|${_patient.lastName}|${_patient.dateOfBirth}|${_patient.phone}`,
      };
    } else {
      console.log("Error saving Patient!", message, code);
      return {} as User;
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getPatients();
      setLoading(false);
    })();
    // const unsubscribe = subscribeToAllDocs<User>(
    //   COLLECTIONS.USERS,
    //   (updatedPatients) => {
    //     setPatients(updatedPatients);
    //     setLoading(false);
    //   },
    //   "user-logs"
    // );

    // return () => {
    //   unsubscribe();
    // };
  }, []);

  return {
    patients,
    loading,
    getPatients,
    savePatient,
  };
};
