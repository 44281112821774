/** @format */

import { Button, Flex, Input } from "@mantine/core";
import { useAppData } from "../../../contexts/AppContext";
import colors, { input_styles } from "../../../constants/theme";
import { Fragment, useState } from "react";
import { useForm } from "@mantine/form";
import styled from "styled-components";

interface Props {
  onSubmit: () => void;
}
function AddActionForm({ onSubmit }: Props) {
  const {
    batchRulesData: { batchRuleActions, saveBatchRuleAction },
    user,
  } = useAppData();

  const [savingAction, setSavingAction] = useState(false);

  const actionForm = useForm<any>({
    initialValues: {
      title: "",
    },
    validate: {
      title: (value) => (value === "" ? "Title required" : null),
    },
  });

  const handleSaveAction = async (values: any) => {
    setSavingAction(true);
    await saveBatchRuleAction({
      title: values.title,
      createdBy: user?.uid,
    });
    setSavingAction(false);
    actionForm.setValues({
      title: "",
    });
  };

  return (
    <form onSubmit={actionForm.onSubmit((values) => handleSaveAction(values))}>
      <Flex h={300}>
        <Flex gap={10} direction={"column"} flex={2}>
          <Title>Saved Actions</Title>
          <Flex
            bg={colors.bg}
            h={"100%"}
            style={{
              border: `2px solid ${colors.primaryColor}`,
              borderRadius: 10,
              overflowY: "scroll",
            }}
            direction={"column"}
            align={"center"}
          >
            {batchRuleActions.map((action) => {
              return (
                <Fragment>
                  <Flex p={10} key={action.id}>
                    <Title> {action.title}</Title>
                  </Flex>
                </Fragment>
              );
            })}
          </Flex>
        </Flex>
        <Flex
          flex={3}
          gap={10}
          direction={"column"}
          justify={"center"}
          align={"center"}
        >
          <Input
            size="lg"
            radius={"md"}
            variant="filled"
            styles={input_styles}
            placeholder="Type in Action"
            {...actionForm?.getInputProps("title")}
          />
          <Button
            type="submit"
            style={{ alignSelf: "center" }}
            size="lg"
            radius={"xl"}
            loading={savingAction}
          >
            Save Action
          </Button>
        </Flex>
      </Flex>
    </form>
  );
}

export default AddActionForm;

const Title = styled.div`
  //styleName: Mobile Header 3;
  font-family: Manrope;
  font-size: 18px;
  line-height: 24.59px;
  text-align: center;
`;
