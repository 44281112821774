/** @format */

import { useEffect, useState } from "react";
import { MessageTemplate } from "../constants";
import { getAllDocs, upsertDoc } from "../repositories/firebase";
import { COLLECTIONS } from "../constants/global";
import { notifications } from "@mantine/notifications";

export interface UseTemplates {
  loading: boolean;
  getTemplates: () => Promise<void>;
  saveTemplate: (_template: MessageTemplate) => Promise<boolean>;
  templates: MessageTemplate[];
}

export const useTemplates = (): UseTemplates => {
  const [templates, setTemplates] = useState<MessageTemplate[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const getTemplates = async () => {
    try {
      const _templates = await getAllDocs<MessageTemplate>(
        COLLECTIONS.MESSAGE_TEMPLATES
      );
      setTemplates(_templates);
    } catch (error) {
      console.log("Error getting templates!", error);
    }
  };

  const saveTemplate = async (_template: MessageTemplate): Promise<boolean> => {
    let id = _template?.id;
    delete _template.id;
    const { success, code, message } = await upsertDoc<MessageTemplate>(
      COLLECTIONS.MESSAGE_TEMPLATES,
      {
        ..._template,
      },
      id,
      {
        createdAt: true,
      }
    );
    if (success) {
      notifications.show({
        title: "Template saved successfully",
        message: "",
        variant: "success",
      });
      getTemplates();
      return true;
    } else {
      notifications.show({
        title: "Could not save Template",
        message: "",
        color: "red",
      });
      return false;
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getTemplates();
      setLoading(false);
    })();
  }, []);

  return {
    loading,
    getTemplates,
    saveTemplate,
    templates,
  };
};
