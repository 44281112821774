import { Flex, Image, Space } from "@mantine/core";
import { assets } from "../constants";
import { ROUTES } from "../constants/routes";
import SidebarItem from "../components/SidebarItem";
import { useAppData } from "../contexts/AppContext";
import { useNavigate } from "react-router-dom";
import {useEffect} from "react";

const SIDEBAR_ITEMS = Object.keys(ROUTES)
  .filter((key) => ROUTES[key].onSidebar)
  .map((key) => {
    let route = ROUTES[key];

    return {
      ...route,
    };
  });

function Sidebar() {
  const {
    currentRouteData: { currentRoute },
    updateUnreadMessagesCount
  } = useAppData();
  const navigate = useNavigate();
  useEffect(() => {
    updateUnreadMessagesCount();
  }, []);
  return (
    <Flex direction={"column"}>
      <Image
        style={{
          alignSelf: "center",
        }}
        w={133}
        mt={20}
        h={128}
        src={assets.images.logo}
      />
      <Space h={"lg"} />
      <Flex direction={"column"} gap={20}>
        {SIDEBAR_ITEMS.map((item) => (
          <SidebarItem
            key={item.path}
            name={item.name}
            isActive={currentRoute.path === item.path}
            onClick={() => {
              navigate(item.path);
            }}
          />
        ))}
      </Flex>
    </Flex>
  );
}

export default Sidebar;
