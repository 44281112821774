/** @format */

import {
  ActionIcon,
  Button,
  Card,
  Flex,
  InputLabel,
  TextInput,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useState } from "react";
import styled from "styled-components";
import { useAppData } from "../../../contexts/AppContext";
import colors, { input_styles } from "../../../constants/theme";
import { DateInput } from "@mantine/dates";
import { IconX } from "@tabler/icons-react";
import { IPatientForm } from "../../../constants";
import moment from "moment";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { COLLECTIONS, formatPhoneNumber } from "../../../constants/global";
import { addSubcollectionDoc } from "../../../repositories/firebase";
const PatientFormInitValues: IPatientForm = {
  email: "",
  phoneNumber: "",
  firstName: "",
  lastName: "",
  birthday: undefined,
  addressLine1: "",
  addressLine2: "",
  city: "",
  state: "",
  zipCode: "",
  medications: [],
};

interface Props {
  initialValues?: any;
  onSubmit: () => void;
}

function EditPatientForm({ onSubmit, initialValues }: Props) {
  const {
    adminsData: { admins },
    patientsData: { savePatient },
    ordersData: { orders },
    user,
  } = useAppData();

  let isEditMode = initialValues !== undefined;
  let order = orders?.find((o) => o.userId === initialValues?.id);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const form = useForm<IPatientForm>({
    initialValues: {
      ...PatientFormInitValues,
      ...(initialValues
        ? {
            email: initialValues?.email,
            name: initialValues?.fullName,
            firstName: initialValues?.firstName,
            lastName: initialValues?.lastName,
            phoneNumber: initialValues?.phone,
            birthday: initialValues?.dateOfBirth,
            type: initialValues?.type as any,
            addressLine1: initialValues?.address?.street || "",
            addressLine2: initialValues?.address?.street2 || "",
            city: initialValues?.address?.city || "",
            state: initialValues?.address?.state || "",
            zipCode: initialValues?.address?.postalCode || "",
            ...initialValues,
          }
        : {}),
    },
    validate: {
      email: (value) => {
        if (!/^\S+@\S+$/.test(value)) return "Invalid email";
        else if (admins.find((admin) => admin.email === value) && !isEditMode) {
          return "Email already exists!";
        } else return null;
      },

      phoneNumber: (value) => (value === "" ? "Phone Number required" : null),
      firstName: (value) => (value === "" ? "Name required" : null),
    },
  });

  function getChangedKeys(initialValues: any, updatedValues: any): string {
    let changedKeys: string[] = [];

    for (const key in updatedValues) {
      if (
        typeof updatedValues[key] === "string" &&
        updatedValues[key] != initialValues[key]
      ) {
        changedKeys.push(key);
      }
    }

    const changedKeysString = changedKeys.join(", ");

    return changedKeysString;
  }
  const handleSavePatient = async (values: IPatientForm) => {
    setIsSubmitting(true);
    const u = await savePatient(
      {
        firstName: values.firstName,
        lastName: values.lastName,
        phone: formatPhoneNumber(values.phoneNumber),
        email: values.email,
        //dateOfBirth: moment(values.birthday).toDate(),
        dateOfBirth: moment.utc(values.birthday).hour(12).toDate(),
        address: {
          street: values.addressLine1,
          street2: values.addressLine2,
          city: values.city,
          state: values.state,
          postalCode: values.zipCode,
        },
        id: initialValues?.id,
      } as any,
      { refetch: true }
    );
    if (u?.key) {
      // add logs
      await addSubcollectionDoc(
        COLLECTIONS.USERS,
        initialValues?.id,
        "user-logs",
        {
          title: `User has been updated.`,
          description: `Some user fields has been updated by ${
            admins.find((admin) => admin.id === user.uid)?.name || user?.email
          }.`,
        },
        {
          createdAt: true,
        }
      );
    }

    setIsSubmitting(false);
    onSubmit();
  };

  return (
    <form onSubmit={form.onSubmit((values) => handleSavePatient(values))}>
      <Flex direction={"column"} gap={30} p={20}>
        <Flex justify={"space-between"} wrap={"wrap"} gap={25}>
          <Flex direction={"column"} flex={4} gap={25}>
            <Flex gap={10} w={"80%"}>
              <TextInput
                radius={"md"}
                styles={input_styles}
                size="xl"
                placeholder="First Name"
                label="First Name"
                name="firstName"
                {...form.getInputProps("firstName")}
              />
              <TextInput
                radius={"md"}
                styles={input_styles}
                size="xl"
                label="Last Name"
                placeholder="Last Name"
                name="lastName"
                {...form.getInputProps("lastName")}
              />
            </Flex>
            <DateInput
              w={"50%"}
              radius={"md"}
              styles={input_styles}
              size="xl"
              placeholder="Birthday"
              label="Birthday"
              name="birthday"
              {...form.getInputProps("birthday")}
            />

            <TextInput
              radius={"md"}
              styles={input_styles}
              size="xl"
              label="Address"
              placeholder="Street Address"
              name="addressLine1"
              {...form.getInputProps("addressLine1")}
            />
            <TextInput
              radius={"md"}
              styles={input_styles}
              size="xl"
              placeholder="Street Address Line 2"
              name="addressLine2"
              {...form.getInputProps("addressLine2")}
            />
            <Flex gap={10}>
              <TextInput
                radius={"md"}
                styles={input_styles}
                size="xl"
                placeholder="City"
                name="city"
                {...form.getInputProps("city")}
              />
              <TextInput
                radius={"md"}
                styles={input_styles}
                size="xl"
                placeholder="State/Province"
                name="state"
                {...form.getInputProps("state")}
              />
            </Flex>
            <TextInput
              radius={"md"}
              w={"50%"}
              styles={input_styles}
              size="xl"
              placeholder="Postal/Zip Code"
              name="zipCode"
              {...form.getInputProps("zipCode")}
            />

            <Flex gap={10}>
              <TextInput
                radius={"md"}
                styles={input_styles}
                size="xl"
                placeholder="Email"
                label="Email"
                name="email"
                {...form.getInputProps("email")}
              />
              <Flex direction={"column"}>
                <InputLabel size="xl">Phone Number</InputLabel>
                <PhoneInput
                  inputStyle={{
                    border: `3px solid ${colors.primaryColor}`,
                    borderRadius: 8,
                    height: 59,
                    width: "100%",
                  }}
                  specialLabel=""
                  preferredCountries={["us", "pk"]}
                  country={"us"}
                  {...form.getInputProps("phoneNumber")}
                  //   value={this.state.phone}
                  //   onChange={(phone) => this.setState({ phone })}
                />
              </Flex>
              {/* <TextInput
                radius={"md"}
                styles={input_styles}
                size="xl"
                placeholder="Phone Number"
                label="Phone Number"
                name="phoneNumber"
                {...form.getInputProps("phoneNumber")}
              /> */}
            </Flex>
          </Flex>
          <Flex flex={1} />
          <Flex direction={"column"} flex={4} gap={10}>
            <InputLabel size="xl">Medications</InputLabel>
            {order?.medications?.map((med: any) => {
              return (
                <Flex gap={10} align={"center"}>
                  <Card flex={1}>
                    <Flex justify={"space-between"}>
                      <Title>{med.itemName}</Title>
                      <Subtitle>{med.dosageForm}</Subtitle>
                    </Flex>
                  </Card>
                  <ActionIcon variant="transparent" color="black" size={"xl"}>
                    <IconX size={"md"} />
                  </ActionIcon>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
        <Button
          type="submit"
          loading={isSubmitting}
          size="lg"
          radius={"xl"}
          style={{ alignSelf: "center" }}>
          Save
        </Button>
      </Flex>
    </form>
  );
}

export default EditPatientForm;

const Title = styled.div`
  font-family: DM Sans;
  font-size: 22px;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
`;

const Subtitle = styled.div`
  font-family: DM Sans;
  font-size: 18px;
  color: #777;
  font-weight: 700;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
`;
