export type Route = { path: string; name: string; onSidebar?: boolean };

export const ROUTES: Record<string, Route> = {
  OrderStatus: {
    path: "/order-status",
    name: "Order Status",
    onSidebar: true,
  },
  PatientLookUp: {
    path: "/patient-look-up",
    name: "Patient Look Up",
    onSidebar: true,
  },
  Messaging: {
    path: "/messaging",
    name: "Messaging",
    onSidebar: true,
  },
  BatchHistory: {
    path: "/batch-history",
    name: "Batch History",
    onSidebar: true,
  },
  Archive: {
    path: "/archive",
    name: "Archive",
    onSidebar: true,
  },
  AdminAccounts: {
    path: "/admin-accounts",
    name: "Admin Accounts",
    onSidebar: true,
  },
  Settings: {
    path: "/settings",
    name: "Settings",
    onSidebar: true,
  },
  Login: {
    path: "/login",
    name: "Login",
    onSidebar: false,
  },
  BatchRules: {
    path: "/batch-rules",
    name: "Batch Rules",
    onSidebar: false,
  },
  BatchPreview: {
    path: "/batch-preview",
    name: "Batch Preview",
    onSidebar: false,
  },
  MessageTemplates: {
    path: "/messaging/templates",
    name: "Templates",
    onSidebar: false,
  },
};
